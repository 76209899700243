import axios from "axios";
import { Apis } from "constants/apis";
import { encrypt } from "utils/security";
import { getToken } from "utils/token";

const uploadPayment = async (payload: any) => {
    const encryptedPayload = { data: encrypt(JSON.stringify(payload)) }
    const { data } = await axios.post(Apis.MANUAL_PAYMENT, encryptedPayload, { headers: { Authorization: getToken() } })
    return data
}

export default uploadPayment