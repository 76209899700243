import React, { useState } from "react";
import {
  Center,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Progress,
  useMediaQuery,
} from "@chakra-ui/react";
import DataUsageIcon from "assets/icons/DataUsageIcon";
import RightArrowIcon from "assets/icons/RightArrowIcon";
// import SearchIcon from 'assets/icons/SearchIcon';
import { useSubscription, useUser } from "hooks/user";
import { BsListTask } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { MdOutlineInventory2 } from "react-icons/md";

const DataUsage = () => {
  const { user } = useUser();
  const { subscription } = useSubscription();
  const [isOpen, setIsOpen] = useState(false);
  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");

  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  const closePopover = () => {
    setIsOpen(false);
  };

  return (
    <Popover
      placement="top-start"
      isOpen={isOpen}
      onOpen={togglePopover}
      onClose={closePopover}
    >
      <PopoverTrigger>
        <Flex
          border="1px solid gray"
          borderRadius="5px"
          w="130px"
          h="34px"
          px="8px"
          py="3px"
          gap="5px"
          mr="12px"
          cursor="pointer"
          tabIndex={0}
        >
          <Center>
            <DataUsageIcon />
          </Center>
          <Center fontSize="14px">Data Usage</Center>
          <Center>
            <RightArrowIcon />
          </Center>
        </Flex>
      </PopoverTrigger>
      <PopoverContent _focus={{ boxShadow: "none" }} w={isLargerThan500 ? "280px" : "200px"}>
        <PopoverBody  >
          <Flex
            w="full"
            flexDir="column"
            justify="center"
            align="center"
            px="10px"
          >
            <Flex w="full" flexDir="row" gap="10px" align="center">
              <BsListTask />
              <Text fontSize="13px">Listing Research</Text>
            </Flex>
            <Flex w="full" flexDir="row" align="center" gap="10px" mt={2}>
              <Progress
                colorScheme="brand"
                w="100%"
                value={user?.listingSearches}
                max={subscription?.listingSearches}
                height="4px"
                borderRadius="10px"
              />
            </Flex>
            <Flex w="full" justify="flex-end" mt={2}>
              <Text color="#CBCBCB" fontSize="12px">
                {user?.listingSearches} / {subscription?.listingSearches}
              </Text>
            </Flex>
          </Flex>
          <Flex
            w="full"
            flexDir="column"
            justify="center"
            align="center"
            px="10px"
          >
            <Flex w="full" flexDir="row" gap="10px" align="center">
              <BiSearch />
              <Text fontSize="13px">Market Research</Text>
            </Flex>
            <Flex w="full" flexDir="row" align="center" gap="10px" mt={2}>
              <Progress
                colorScheme="brand"
                w="100%"
                value={user?.marketSearches}
                max={subscription?.marketSearches}
                height="4px"
                borderRadius="10px"
              />
            </Flex>
            <Flex w="full" justify="flex-end" mt={2}>
              <Text color="#CBCBCB" fontSize="12px">
                {user?.marketSearches} / {subscription?.marketSearches}
              </Text>
            </Flex>
          </Flex>
          <Flex
            w="full"
            flexDir="column"
            justify="center"
            align="center"
            px="10px"
          >
            <Flex w="full" flexDir="row" gap="10px" align="center">
              <MdOutlineInventory2 />
              <Text fontSize="13px">Store Research</Text>
            </Flex>
            <Flex w="full" flexDir="row" align="center" gap="10px" mt={2}>
              <Progress
                colorScheme="brand"
                w="100%"
                value={user?.storeSearches}
                max={subscription?.storeSearches}
                height="4px"
                borderRadius="10px"
              />
            </Flex>
            <Flex w="full" justify="flex-end" mt={2}>
              <Text color="#CBCBCB" fontSize="12px">
                {user?.storeSearches} / {subscription?.storeSearches}
              </Text>
            </Flex>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default DataUsage;
