import { ButtonProps, InputGroupProps, InputProps, InputRightElementProps } from "@chakra-ui/react";

export const InputRight : InputRightElementProps ={
    h:"full",
    w:"4.5rem"
}

export const ShowBtn : ButtonProps = {
    bgColor:"transparent",
    h:"auto",
    size: "sm",
    _hover: {
        bgColor: "transparent",
      }
}

export const InputWrapper : InputGroupProps = {
    display:"flex"
}

export const InputField : InputProps = {
    borderRadius:"6px",
    h:"50px",
    paddingLeft:"20px",
    border:"1px solid #CDCDCD",
    backgroundColor: "#fff"
}
