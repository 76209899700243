import React from "react"
import { Formik, FormikHelpers, FormikValues } from 'formik'

type formikValues = FormikValues & object;

interface Props {
    children?: React.ReactNode | React.ReactNode[] | undefined;
    initialValues: formikValues;
    onSubmit: (values: formikValues, formikHelpers: FormikHelpers<formikValues>) => any;
    validationSchema?: any;
    enableReinitialize?: boolean;
}

const Form: React.FC<Props> = ({ children, initialValues, onSubmit, validationSchema }) => {
    return (
        <Formik 
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {() => <>{children}</>}
        </Formik>
    )
}
  
export default Form