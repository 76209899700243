//@ts-nocheck
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

// Define the props for the chart component
interface IChartProps {
  categories: string[];
  dataCount: ApexAxisChartSeries;
  yAxis: string
}

const AreaChart: React.FC<IChartProps> = ({ categories, yAxis, dataCount }) => {
    const options: ApexOptions = {
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: dataCount[0].name,
          align: 'left'
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            title: {
                text: yAxis,
            },
        },
        legend: {
          horizontalAlign: 'left'
        },
        tooltip: {
            y: {
                formatter: (val: number) => `${val}`,
            },
        },
    };

  return (
    <ReactApexChart
      options={options}
      series={dataCount}
      type="area"
      height={350}
    />
  );
};


export default AreaChart