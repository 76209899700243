import axios from "axios";
import { Apis } from "constants/apis";
import { CreateNewsLetterService } from "types/services";
import { getToken } from "utils/token";

const createNewsLetter: CreateNewsLetterService = async (payload) => {

  const { data } = await axios.post(Apis.SEND_NEWSLETTER, payload, {
    headers: { Authorization: getToken() },
  });

  return data;
};

export default createNewsLetter;
