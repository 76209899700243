import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import addProdcut from "services/Products/addProductToCollection";
import getFavouriteProducts from "services/Products/getFavourite";
import GetProducts from "services/Products/GetProducts";
import removeProduct from "services/Products/removeProductCollection";
import { useAppContext } from "./app";
import getDashboardProducts from "services/Products/getDashboardProducts";
import getProductDetails from "services/Products/getProductDetails";
import { IGetAllProductsPayload, IGetProductDiscoveryPayload } from "types/globals";
import searchProducts from "services/Products/searchProductDiscovery";

export const useGetProducts = (payload?: IGetAllProductsPayload) => {
  const toast = useToast();

  const onError = () => {
    toast({ title: "No Products.", status: "error" });
  };

  const { isLoading, data, refetch } = useQuery(
    ["products", payload],
    () => GetProducts(payload),
    { onError }
  );

  return { isLoading, products: data?.products, total: data?.total, refetch };
};

export const useGetProductDiscovery = (payload?: IGetProductDiscoveryPayload) => {
  const toast = useToast();

  const onError = () => {
    toast({ title: "No Products.", status: "error" });
  };

  const { isLoading, data, refetch } = useQuery(
    ["products", payload],
    () => searchProducts(payload),
    { onError }
  );

  return { isLoading, products: data?.products, total: data?.total, refetch };
};

export const useGetProductDetails = (payload: number) => {
  const toast = useToast();

  const onError = () => {
    toast({ title: "No product found", status: "error" });
  };

  const { isLoading, data, refetch } = useQuery(
    ["product", payload],
    () => getProductDetails(payload),
    { onError }
  );

  return { isLoading, product: data, refetch };
};


export const useGetDashboardProducts = () => {
  const { data, isLoading, isFetching, isError, error } = useQuery(["getDashboardProducts"], getDashboardProducts, { refetchOnWindowFocus: true });
  return { dashboardProducts: data, isLoading, isFetching, isError, error };
}

export const useGetFavouriteProducts = () => {
  const toast = useToast();
  const { productPayload } = useAppContext()

  const { products } = useGetProducts(productPayload);
  const itemId = products?.map((product: { itemId: any; }) => product.itemId);
  const payload = {
    itemIds: itemId
  }
  
  const onError = () => {
    toast({ title: "No Products.", status: "error" });
  };

  const { isLoading, data, refetch } = useQuery(
    ["favouriteProducts",  payload],
    () => getFavouriteProducts(payload),
    { onError }
  );

  return { isLoading, products: data, refetch };
};

export const useAddProductToCollection = () =>{
  const toast = useToast()
  const queryClient = useQueryClient()

  const onSuccess = () => {
      toast({title:"Product added to collection!!", status:"success"})
      queryClient.invalidateQueries(["getProductsCollection",1])
      queryClient.invalidateQueries(['favouriteProducts'])
  }

  const onError = () => {
      toast({title:"Product not added.", status:"error"})
  }

  const {mutateAsync, isLoading} = useMutation(addProdcut, { onSuccess, onError })
  return { add: mutateAsync, isLoading }
}

export const useRemoveProductFromCollection = (collectionId: number | undefined) =>{
  const toast = useToast()
  const queryClient = useQueryClient()

  const onSuccess = () => {

    toast({ title: "Product removed from collection!!", status: "success" })
      queryClient.invalidateQueries(["getProductsCollection",collectionId])
      queryClient.invalidateQueries(['products'])
      queryClient.invalidateQueries(['favouriteProducts'])
  }

  const onError = () => {
      toast({ title: "Product couldn't be removed to collection", status: "warning", duration: 2000 })
  }

  const {mutateAsync, isLoading} = useMutation(removeProduct, { onSuccess, onError })
  return { remove: mutateAsync, isLoading }
}


