import { Flex, Text, Box } from "@chakra-ui/react";
import * as styles from "./styles";
import FormButton from "components/Form/Button/FormButton";
import Form from "components/Form/Form";
import {
  useRecoveryEmail,
  useUser,
  useVerify,
  usePhoneVerify,
} from "hooks/user";
import { BsGiftFill } from "react-icons/bs";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { useMediaQuery } from "@chakra-ui/react";
import { useAppContext } from "hooks/app";
import "./styles.scss"
interface IPhoneVerification {
  onBack: () => void;
  phone: string;
}
const PhoneVerification: React.FC<IPhoneVerification> = ({ onBack, phone }) => {
  const { user } = useUser();
  const { isVerifying } = useVerify();
  const { validateWhatsappOtp, sendWhatsappOtp } = usePhoneVerify();
  const [otp, setOtp] = useState();
  const { email } = useRecoveryEmail();
  const [isLargerThan470] = useMediaQuery("(min-width: 470px)");
  const { closeModal } = useAppContext();
  const [success, setSuccess] = useState(false)
  const [showRewardMessage, setShowRewardMessage] = useState("")
  const handleVerify = async () => {
    try {
      if (email || user?.email) {
        await validateWhatsappOtp({ phone: phone, code: otp }).then((data) => {
        if(data.status == "success"){
          setSuccess(true);
          setShowRewardMessage(data.message);
        }
      });
      }
    } catch (err) {}
  };

  const handleResend = async () => {
    try {
      await sendWhatsappOtp({ phone: phone });
    } catch (err) {}
  };

  const handleChange = (otp: any) => setOtp(otp);

  const handleBack = () => {
    onBack();
  };
  const handleCloseModal = () => {
    closeModal?.()
  }

  return (
    <div>
      {!success ? (
        <>
          <Flex flexDir="column" align="flex-start" justify="flex-start">
            <Text {...styles.WelcomeText}>Almost Done!</Text>
            <Flex {...styles.GapWorries}>
              <Text {...styles.Worries}>
                We have sent an OTP code at your WhatsApp number {" "}
                <Text as="span" {...styles.WorriesEmail}>
                  {phone}
                </Text>
              </Text>
            </Flex>
          </Flex>
          <Flex sx={styles.FormWrapper} w={isLargerThan470 ? "370px" : "90%"}>
            <Form
              initialValues={{
                code: "",
              }}
              onSubmit={handleVerify}
            >
              <Flex sx={styles.TextInputWrapper}>
                <Text sx={styles.InputLabel}>Enter Code</Text>
                <OtpInput
                  onChange={handleChange}
                  numInputs={6}
                  value={otp}
                  inputStyle="otp-input"
                  focusStyle="otp-input-focus"
                />
              </Flex>
              <Flex sx={styles.SubmitWrapper}>
                <FormButton
                  disabled={isVerifying}
                  isLoading={isVerifying}
                  h="40px"
                  w="fit-content"
                  text="Verify Code"
                  variant="primary"
                  borderRadius="6px"
                  fontSize="14px"
                  fontWeight="500"
                  marginBottom="20px"
                />
                <Box
                  {...styles.Resend}
                  fontSize={isLargerThan470 ? "15px" : "11px"}
                >
                  <Text>Didn't receive the OTP?</Text>
                  <Text color="#185ADB" cursor="pointer" onClick={handleResend}>
                    Click to resend
                  </Text>
                </Box>
                <Flex {...styles.BackWrapper}>
                  <IoIosArrowRoundBack {...styles.IconBack} />
                  <Text {...styles.BackText} onClick={handleBack}>
                    Enter a different number
                  </Text>
                </Flex>
              </Flex>
            </Form>
          </Flex>
        </>
      )
      : (
        <div className="whatsappReward">
          <BsGiftFill size={50} className="rewardIcon" />
          <h3 className="rewardTitle">Reward</h3>
          <p className="rewardDetails">{showRewardMessage}</p>
          <button className="btnPrimary"  onClick={handleCloseModal}>
            Close
          </button>
        </div>
      )
    }
    </div>
  );
};
export default PhoneVerification;
