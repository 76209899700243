import { Card, Skeleton, Space } from 'antd';

import "./skeleton.scss"
const ProductSkeletonCard: React.FC = () => {

  return (
    <Card
    className="skeleton-card"
    >
        <Space className="skeleton-container">
            <Skeleton.Image className="skeleton-image" active />
            <Skeleton.Button active size={"small"} block={false} className="price-skeleton" />
            <Skeleton.Input active size={"small"} block={false} className="price-skeleton" />
            <Skeleton.Input active size={"small"} block={false} className="price-skeleton" />
            <Skeleton.Input active size={"small"} block={false} className="price-skeleton" />
        </Space>
    </Card>
  );
};

export default ProductSkeletonCard;