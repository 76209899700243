import React, { createContext, useState } from 'react'
import { IBillingContext, IBillingState, ICouponState, IPlanState, RFC } from 'types/globals'

export const BillingContext = createContext<IBillingContext>({})

const BillingProvider: React.FC<RFC> = ({ children }) => {
    const [billingState, setBillingState] = useState<IBillingState>({})
    const [plan, setPlan] = useState<IPlanState>({})
    const [coupon, setCoupon] = useState<ICouponState>({})
    const [invoiceId, setInvoiceId] = useState<number>(0);

    const setActivePlan = (planIndex: number) => setBillingState({ ...billingState, selectedPlanIndex: planIndex })

    const isPlanActive = (planIndex: number) => billingState?.selectedPlanIndex === planIndex

    const selectPaymentMethod = (methodIndex: number) => setBillingState({ ...billingState, selectedPaymentMethodIndex: methodIndex })

    const selectedPlan = (name: string, description: string, price: any, interval: any, interval_count: any, id: number) => setPlan({ ...plan, name, description, price, interval, interval_count, id })

    const setCouponData = (discount?: number, type?: string, discountedPrice?: number, couponId?: any) => setCoupon({ ...coupon, discount, type, discountedPrice, couponId })

    const isPaymentMethodActive = (methodIndex: number) => billingState.selectedPaymentMethodIndex === methodIndex

    const resetBillingDetails = () => {
        setBillingState({});
        setPlan({});
        setCoupon({});
        setInvoiceId(0);
    }

    return (
        <BillingContext.Provider value={{
            billingState,
            setBillingState,
            plan,
            setPlan,
            setActivePlan,
            selectedPlan,
            isPlanActive,
            coupon,
            setCoupon,
            setCouponData,
            selectPaymentMethod,
            isPaymentMethodActive,
            invoiceId,
            setInvoiceId,
            resetBillingDetails
        }}>
            {children}
        </BillingContext.Provider>
    )
}

export default BillingProvider