import axios from 'axios';
import { ISocialVideoResponse } from 'types/services';
import { Apis } from 'constants/apis';
import { getToken } from 'utils/token';

const activeSocialVideos = async (): Promise<ISocialVideoResponse[]> => {
    const { data } = await axios.get(`${Apis.GET_ACTIVE_SOCIAL_VIDEOS}`, { headers: { Authorization: getToken() } });
    return data;
};

export default activeSocialVideos;
