import React, { useState, ChangeEvent, FormEvent } from 'react';
import './styles.scss';
import { useExtensionUninstallFeedback } from 'hooks/useUninstallFeedback';
import { useNavigate } from 'react-router-dom';

interface Feedback {
  reasons: string[];
  otherReason?: string;
  additionalFeedback: string;
}

const UninstallFeedbackForm: React.FC = () => {
    const [feedback, setFeedback] = useState<Feedback>({
        reasons: [],
        additionalFeedback: ''
    });
    const { uninstallFeedback, isLoading } = useExtensionUninstallFeedback();
    const navigate = useNavigate()
    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setFeedback(prevState => {
        const updatedReasons = checked
            ? [...prevState.reasons, name]
            : prevState.reasons.filter(reason => reason !== name);
        return { ...prevState, reasons: updatedReasons };
        });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFeedback(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        uninstallFeedback(feedback)
        setFeedback({
        reasons: [],
        additionalFeedback: ''
        });
        setTimeout(() => {
            navigate("/")
        }, 1500);
    };

  return (
    <div className="uninstall-feedback-form">

        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <h3>We are sorry to see you go. Please help us improve our product by telling us why you uninstalled the extension. Check all that apply.</h3>
                <div className="checkbox-group">
                    <label>
                    <input
                        type="checkbox"
                        name="performance"
                        checked={feedback.reasons.includes('performance')}
                        onChange={handleCheckboxChange}
                    />
                    Performance Issues
                    </label>
                    <label>
                    <input
                        type="checkbox"
                        name="usability"
                        checked={feedback.reasons.includes('usability')}
                        onChange={handleCheckboxChange}
                    />
                    Usability Issues
                    </label>
                    <label>
                    <input
                        type="checkbox"
                        name="features"
                        checked={feedback.reasons.includes('features')}
                        onChange={handleCheckboxChange}
                    />
                    Lack of Features
                    </label>
                    <label>
                    <input
                        type="checkbox"
                        name="alternatives"
                        checked={feedback.reasons.includes('alternatives')}
                        onChange={handleCheckboxChange}
                    />
                    Found a Better Alternative
                    </label>
                    <label>
                    <input
                        type="checkbox"
                        name="bugs"
                        checked={feedback.reasons.includes('bugs')}
                        onChange={handleCheckboxChange}
                    />
                    Encountered Bugs
                    </label>
                    <label>
                    <input
                        type="checkbox"
                        name="other"
                        checked={feedback.reasons.includes('other')}
                        onChange={handleCheckboxChange}
                    />
                    Other
                    </label>
                </div>
            </div>
            {feedback.reasons.includes('other') && (
            <div className="form-group">
                <label>Please specify:</label>
                <input
                type="text"
                name="otherReason"
                value={feedback.otherReason || ''}
                onChange={handleChange}
                />
            </div>
            )}
            <div className="form-group">
            <label>Additional Feedback:</label>
            <textarea
                name="additionalFeedback"
                value={feedback.additionalFeedback}
                onChange={handleChange}
                rows={4}
                placeholder="Tell us more about your experience..."
            />
            </div>
            <button type="submit">Send Feedback</button>
        </form>
    </div>
  );
};

export default UninstallFeedbackForm;
