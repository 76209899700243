export enum PERMISSIONS {
    LOGIN = "LOGIN",
    GET_USER = "GET_USER",
    GET_MY_ACCOUNT = "GET_MY_ACCOUNT",
    GET_ALL_USERS = "GET_ALL_USERS",
    CREATE_USER = "CREATE_USER",
    UPDATE_USER = "UPDATE_USER",
    UPDATE_MY_ACCOUNT = "UPDATE_MY_ACCOUNT",
    DELETE_USER = "DELETE_USER",
    DELETE_MY_ACCOUNT = "DELETE_MY_ACCOUNT",
    CREATE_COLLECTION = "CREATE_COLLECTION",
    CREATE_MY_COLLECTION = "CREATE_MY_COLLECTION",
    UPDATE_COLLECTION = "UPDATE_COLLECTION",
    UPDATE_MY_COLLECTION = "UPDATE_MY_COLLECTION",
    DELETE_COLLECTION = "DELETE_COLLECTION",
    DELETE_MY_COLLECTION = "DELETE_MY_COLLECTION",
    GET_MY_COLLECTION = "GET_MY_COLLECTION",
    CREATE_PLAN = "CREATE_PLAN",
    UPDATE_PLAN = "UPDATE_PLAN",
    DELETE_PLAN = "DELETE_PLAN",
    CREATE_COMPANY = "CREATE_COMPANY",
    UPDATE_COMPANY = "UPDATE_COMPANY",
    UPDATE_MY_COMPANY = "UPDATE_MY_COMPANY",
    DELETE_COMPANY = "DELETE_COMPANY",
    DELETE_MY_COMPANY = "DELETE_MY_COMPANY",
    GET_COMPANY = "GET_COMPANY",
    GET_MY_COMPANY = "GET_MY_COMPANY",
    GET_ALL_COMPANIES = "GET_ALL_COMPANIES",
    RESEND_CODE = "RESEND_CODE",
    SEARCH_DARAZ_PRODUCTS = "SEARCH_DARAZ_PRODUCTS",
    GET_MY_SUBSCRIPTION = "GET_MY_SUBSCRIPTION",
    SEED_PRODUCTS = "SEED_PRODUCTS",
    ADD_BLOG_CATEGORY = "ADD_BLOG_CATEGORY",
    CREATE_BLOG = "CREATE_BLOG",
    DELETE_BLOG = "DELETE_BLOG",
    DELETE_CATEGORY = "DELETE_CATEGORY",
    UPDATE_BLOG_CATEGORY = "UPDATE_BLOG_CATEGORY",
    UDPATE_BLOG = "UDPATE_BLOG",
    GET_COLLECTION_PRODUCTS = "GET_COLLECTION_PRODUCTS",
    REMOVE_COLLECTION_PRODUCT = " REMOVE_COLLECTION_PRODUCT",
    ADD_COLLECTION_PRODUCT = "ADD_COLLECTION_PRODUCT",
    CHECK_IN_COLLECTION = "CHECK_IN_COLLECTION",
    GET_COUPONS = "GET_COUPONS",
    GET_COUPON = "GET_COUPON",
    CREATE_COUPON = "CREATE_COUPON",
    UPDATE_COUPON = "UPDATE_COUPON",
    DELETE_COUPON = "DELETE_COUPON",
    GET_STRIPE_INVOICES = "GET_STRIPE_INVOICES",
    GET_ALL_INVOICES = "GET_ALL_INVOICES",
    GET_PENDING_INVOICES = "GET_PENDING_INVOICES",
    GET_INVOICES = "GET_INVOICES",
    MANUAL_PAYMENT = "MANUAL_PAYMENT",
    GET_PERMISSIONS = "GET_PERMISSIONS",
    ADD_PERMISSIONS = "ADD_PERMISSIONS",
    GET_ROLES = "GET_ROLES",
    UPDATE_ROLE = "UPDATE_ROLE",
    CREATE_ROLE = "CREATE_ROLE",
    DELETE_ROLE = "DELETE_ROLE",
    UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION",
    CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION",
    GET_ALL_SUBSCRIPTIONS = "GET_ALL_SUBSCRIPTIONS",
    GET_PLAN_DETAIL_DATA = "GET_PLAN_DETAIL_DATA",
    GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA",
    GET_USER_BY_ID = "GET_USER_BY_ID",
    DELETE_USER_BY_ID = "DELETE_USER_BY_ID",
    ACCESS_ADMIN_DASHBOARD = "ACCESS_ADMIN_DASHBOARD",
    ACCESS_ADMIN_REPORTS = "ACCESS_ADMIN_REPORTS",
    ACCESS_ADMIN_COUPONS = "ACCESS_ADMIN_COUPONS",
    ACCESS_ADMIN_USER_MANAGEMENT = "ACCESS_ADMIN_USER_MANAGEMENT",
    ACCESS_ADMIN_PRODUCTS = "ACCESS_ADMIN_PRODUCTS",
    ACCESS_ADMIN_CUSTOMERS = "ACCESS_ADMIN_CUSTOMERS",
    ACCESS_ADMIN_SUBSCRIPTIONS = "ACCESS_ADMIN_SUBSCRIPTIONS",
    ACCESS_ADMIN_ROLES = "ACCESS_ADMIN_ROLES",
    ACCESS_ADMIN_MONTHLY_SALES = "ACCESS_ADMIN_MONTHLY_SALES",
    ACCESS_ADMIN_PAYMENT_HISTORY= "ACCESS_ADMIN_PAYMENT_HISTORY",
    ACCESS_ADMIN_BLOG_MANAGEMENT = "ACCESS_ADMIN_BLOG_MANAGEMENT",
    ACCESS_ADMIN_NOTIFICATIONS_MANAGEMENT = "ACCESS_ADMIN_NOTIFICATIONS",
    ACCESS_ADMIN_FAQ_MANAGEMENT = "ACCESS_ADMIN_FAQ_MANAGEMENT",
    ACCESS_ADMIN_NEWS_LETTER = "ACCESS_ADMIN_NEWS_LETTER",
    GET_SUBSCRIPTION_UPDATE_LOGS = "GET_SUBSCRIPTION_UPDATE_LOGS",
    ACCESS_ADMIN_SUBSCRIPTION_UPDATE_LOGS = "ACCESS_ADMIN_SUBSCRIPTION_UPDATE_LOGS",
    ACCESS_ADMIN_REVIEW_MANAGEMENT="ACCESS_ADMIN_REVIEW_MANAGEMENT",
    ACCESS_ADMIN_WEBSITE_SETTINGS="ACCESS_ADMIN_WEBSITE_SETTINGS",
    ACCESS_ADMIN_SOCIAL_VIDEOS_MANAGEMENT="ACCESS_ADMIN_SOCIAL_VIDEOS_MANAGEMENT",
    UPDATE_SOCIAL_VIDEOS = "UPDATE_SOCIAL_VIDEOS",
    CREATE_SOCIAL_VIDEOS = "CREATE_SOCIAL_VIDEOS",
    DELETE_SOCIAL_VIDEOS = "DELETE_SOCIAL_VIDEOS",
    GET_SOCIAL_VIDEOS = "GET_SOCIAL_VIDEOS",


}