import axios from "axios";
import { Apis } from "constants/apis";
import { UpdateNotificationsService } from "types/services";
import { getToken } from "utils/token";

const updateNotification: UpdateNotificationsService = async (payload) => {
  
  const { data } = await axios.put(Apis.NOTIFICATION, {...payload, location: JSON.stringify(payload.location)} , {
    headers: { Authorization: getToken() },
  });

  return data;
};

export default updateNotification;
