import { Center, Flex } from "@chakra-ui/react";
import useScrollToTop from "hooks/useScrollToTop";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import * as styles from "./styles"

interface Props {
    children: React.ReactNode;
    title: string;
}

const UserDashbaordLayout:FC<Props> =({children, title})=>{
    useScrollToTop()

    return(
        <>
            <Helmet>
                 <title>{title}</title>
            </Helmet>
            <Flex {...styles.MainWrapper} >
                 <Flex {...styles.InnerWrapper}>
                    <Center {...styles.wrapper}>
                        {children}
                    </Center>
                 </Flex>
            </Flex>
        </>
    )
}
export default UserDashbaordLayout