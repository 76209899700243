import React from 'react'

const DataUsageIcon = () => {
  return (
    <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.3513 9.31593C17.6577 7.24505 16.8262 4.10322 13.9593 2.10466C11.162 0.154738 7.36691 0.044974 4.44939 1.8408C1.38004 3.73046 0.336582 6.95019 0.645687 9.31076C0.939879 9.27719 1.23543 9.24447 1.53052 9.20874C1.8211 9.17388 2.11167 9.13643 2.43614 9.09597C2.4619 9.27676 2.48676 9.44893 2.51568 9.65167C1.71174 9.75068 0.924966 9.84796 0.139551 9.94481C-0.365682 7.50203 0.435098 3.96031 3.62737 1.69401C6.70893 -0.493514 10.9451 -0.567981 14.0985 1.5029C17.3491 3.63791 18.4007 7.19038 17.8688 9.9362C16.9889 9.83591 16.105 9.73475 15.2025 9.63187C15.2251 9.44549 15.245 9.28407 15.2708 9.07358C15.969 9.15451 16.6608 9.235 17.3513 9.3155V9.31593Z" fill="#0A1931"/>
        <path d="M9.09109 10.1252C8.13349 10.1252 7.47913 9.61165 7.3413 8.88247C7.20437 8.15674 7.62239 7.43273 8.3251 7.17791C9.06894 6.90802 9.8878 7.16499 10.2904 7.81109C10.3804 7.95529 10.4726 8.02029 10.6529 8.03535C12.1442 8.16234 13.6346 8.30094 15.125 8.4361C15.1399 8.43739 15.1579 8.43438 15.1692 8.44127C15.2488 8.48948 15.327 8.53941 15.4051 8.58891C15.3288 8.6397 15.2565 8.72751 15.1751 8.73569C14.6215 8.79122 14.0657 8.82953 13.5107 8.87343C12.5735 8.94747 11.6362 9.02538 10.6985 9.09253C10.5141 9.10587 10.4143 9.17216 10.3239 9.33186C10.0279 9.85571 9.54842 10.1114 9.09018 10.1252H9.09109ZM7.90844 8.59407C7.90573 9.12438 8.34905 9.56 8.89948 9.56688C9.46481 9.5742 9.92486 9.1472 9.92983 8.60957C9.9348 8.07539 9.47295 7.63074 8.91575 7.63332C8.36578 7.63633 7.91116 8.06979 7.90844 8.59407Z" fill="#0A1931"/>
        <path d="M16.5921 5.74495C16.5257 5.80306 16.5013 5.83964 16.467 5.85256C16.1886 5.95716 15.9093 6.0596 15.6282 6.15602C15.602 6.16506 15.5284 6.12718 15.5288 6.11255C15.5297 6.07381 15.5496 6.01311 15.5794 6.00106C15.8564 5.89173 16.1362 5.78842 16.4186 5.692C16.4539 5.67995 16.5081 5.71783 16.5921 5.74495Z" fill="#0A1931"/>
        <path d="M16.5466 8.62193C16.4038 8.62193 16.261 8.62709 16.1187 8.61849C16.0789 8.61633 16.0418 8.57458 16.0034 8.55134C16.0405 8.51905 16.0766 8.46051 16.115 8.45922C16.3921 8.44932 16.67 8.44631 16.947 8.45319C16.9931 8.45449 17.0378 8.51432 17.083 8.54703C17.032 8.572 16.9822 8.61418 16.9294 8.61849C16.8028 8.62925 16.6745 8.62193 16.5466 8.62193Z" fill="#0A1931"/>
        <path d="M1.81592 5.7372C1.87602 5.72428 1.94562 5.68641 1.99397 5.70319C2.2032 5.77637 2.40656 5.8659 2.628 6.01053C2.56789 6.02947 2.49649 6.07811 2.44904 6.06218C2.2389 5.99159 2.036 5.90249 1.83038 5.82027C1.82541 5.79273 1.82089 5.76518 1.81592 5.7372Z" fill="#0A1931"/>
        <path d="M3.45215 3.41541C3.47791 3.38959 3.51225 3.35515 3.57597 3.29102C3.73911 3.45932 3.88734 3.6018 4.01974 3.75633C4.05544 3.79808 4.04279 3.87685 4.05906 3.9793C3.83401 3.7701 3.65867 3.60696 3.4526 3.41541H3.45215Z" fill="#0A1931"/>
        <path d="M1.66303 8.62163C1.56542 8.62163 1.46691 8.62937 1.37065 8.61775C1.33359 8.61345 1.30196 8.56567 1.26807 8.53769C1.29563 8.511 1.3223 8.4615 1.35077 8.46064C1.56858 8.45418 1.7864 8.45461 2.00422 8.4615C2.03179 8.46236 2.07969 8.51315 2.07834 8.53898C2.07698 8.56696 2.03089 8.61302 2.00061 8.6156C1.88899 8.62464 1.77556 8.61947 1.66303 8.61947C1.66303 8.62034 1.66303 8.62077 1.66303 8.62163Z" fill="#0A1931"/>
        <path d="M6.12829 1.7251C6.18071 1.75953 6.25798 1.78278 6.28194 1.83013C6.49976 2.26229 6.50247 2.30663 6.33571 2.40391C6.24895 2.19859 6.16354 1.99671 6.07812 1.79483C6.09485 1.77159 6.11157 1.74834 6.12829 1.7251Z" fill="#0A1931"/>
        <path d="M9.00175 1.91798C8.92176 1.8801 8.84584 1.86202 8.84494 1.8405C8.83455 1.66401 8.83545 1.4871 8.84087 1.31019C8.84132 1.29168 8.90866 1.2538 8.9177 1.26025C8.95204 1.28436 8.99633 1.32224 8.99814 1.35667C9.00627 1.52541 9.00175 1.69457 9.00175 1.91841V1.91798Z" fill="#0A1931"/>
        <path d="M11.5474 2.31917C11.6274 2.13752 11.7015 1.96836 11.7756 1.79962C11.7955 1.79145 11.8149 1.78284 11.8348 1.77466C11.8592 1.82846 11.9157 1.89088 11.903 1.93478C11.7982 2.30023 11.7259 2.37857 11.5474 2.31874V2.31917Z" fill="#0A1931"/>
        <path d="M13.8398 3.74086C14.003 3.58289 14.1291 3.46064 14.2574 3.33667C14.3966 3.39478 14.4196 3.47915 14.2945 3.55189C14.1643 3.62765 14.1155 3.90099 13.8398 3.74129V3.74086Z" fill="#0A1931"/>
    </svg>
  )
}

export default DataUsageIcon