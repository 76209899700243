import axios from "axios";
import { Apis } from "constants/apis";
import { DeleteNotificationsService } from "types/services";
import { getToken } from "utils/token";

const deleteNotification: DeleteNotificationsService = async (payload) => {
  
  const { data } = await axios.delete(`${Apis.NOTIFICATION}${payload}`, {
    headers: { Authorization: getToken() },
  });

  return data;
};

export default deleteNotification;