import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { Modals } from 'constants/modals';
import { useAppContext } from 'hooks/app';
import React from 'react'
import * as styles from "./styles"
interface Props {
    children?: React.ReactNode | React.ReactNode[];
    modal: Modals;
    onModalCloseCallBack?: (...args: any[]) => any;
}

const ModalLayout: React.FC<Props> = ({ children, modal, onModalCloseCallBack }) => {
    const { appState, closeModal } = useAppContext()

    const onCloseHandler = () => {
        onModalCloseCallBack?.();
        closeModal?.();
    }

    return (
        <Modal isCentered isOpen={appState?.modal === modal} onClose={() => onCloseHandler()} >
            <ModalOverlay />
            <ModalContent {...styles.Content} >
                <ModalCloseButton boxShadow="unset !important" outline="none !important" />
                {children}
            </ModalContent>
        </Modal>
    )
}

export default ModalLayout