import { Box, Text, Flex, Button } from "@chakra-ui/react";
import ModalLayout from "components/ModalLayout/ModalLayout";
import { Modals } from "constants/modals";

import * as styles from "./styles";
import { useConfirmationModal } from "hooks/useConfirmationModal";
import { CiCircleQuestion } from "react-icons/ci";
import { useAppContext } from "hooks/app";
import { useQueryClient } from "@tanstack/react-query";

const Confirmation = () => {
  const { heading, description, reason, onYesCallback, onNoCallback } = useConfirmationModal();
  const client = useQueryClient();
  const { closeModal } = useAppContext();

  const confirmHandler = () => {
    onYesCallback?.();
    closeModal?.();
  };

  const handleClose = () => {
    client.setQueryData(["confirmationModal"], {});
    onNoCallback?.();
    closeModal?.();
  };

  return (
    <ModalLayout modal={Modals.CONFIRMATION}>
      <Box {...styles.ModalWrapper}>
        <Flex direction="column" justify="center" align="center">
          <CiCircleQuestion style={{ display: "inline", fontSize: "4rem", color: "#777777" }} />
          <Text {...styles.ConfirmHeading}>{heading}</Text>
          <Text {...styles.confirmDescription} dangerouslySetInnerHTML={{ __html: description ?? '' }}></Text>
        </Flex>
        {reason && <Box>
          {reason}
        </Box> }
        <Flex justify="center" gap="1rem" marginTop="2rem">
          <Button
            {...styles.ConfirmButton}
            onClick={confirmHandler}
            variant="primary"
          >
            <Text as="span">
              Yes
            </Text>
          </Button>
          <Button
            {...styles.CancelButton}
            onClick={handleClose}
            variant="outline"
          >
            No
          </Button>
        </Flex>
      </Box>
    </ModalLayout>
  );
};

export default Confirmation;
