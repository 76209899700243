import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import Transition from 'components/Transition';
import 'draft-js/dist/Draft.css';
import { useState } from 'react';
import { IoMdAddCircle } from 'react-icons/io';
import SocialVideosTable from './SocialVideosTable/SocialVideosTable';
import AddUpdateSocialVideo from './AddUpdateSocialVideo/AddUpdateSocialVideo';

const SocialVideos = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [editedSocialVideo, setEditedSocialVideo] = useState();
    const [type, setType] = useState('');

    const openAddDrawer = () => {
        onOpen();
        setType('add');
    };
    return (
        <Transition>
            <Flex p="20px" alignItems="center" w="100%" mt="65px" backgroundColor="#f3f3f3" overflowY="auto" minH="93vh" flexDir="column">
                <Flex flexDirection="column" gap="20px" w="100%">
                    <Flex justifyContent="space-between" align="center">
                        <Text fontSize="24px" fontWeight={700}>
                            Social Videos
                        </Text>
                        <IoMdAddCircle color="#185ADB" fontSize="40px" cursor="pointer" onClick={openAddDrawer} />
                    </Flex>
                    <AddUpdateSocialVideo socialVideoToEdit={editedSocialVideo} type={type} isOpen={isOpen} onClose={onClose} />
                    <Box bg="#ffffff" boxShadow="0px 2px 5px rgba(0, 0, 0, 0.1);" p="40px">
                        <SocialVideosTable setType={setType} isOpen={isOpen} onClose={onClose} onOpen={onOpen} setEditedSocialVideo={setEditedSocialVideo} />
                    </Box>
                </Flex>
            </Flex>
        </Transition>
    );
};

export default SocialVideos;
