import {
  Flex,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { AiOutlineYoutube, AiOutlineWhatsApp, AiOutlineChrome} from "react-icons/ai";
import {CiFacebook, CiInstagram, CiLinkedin} from 'react-icons/ci'

import WhiteLogo from "assets/svgs/whiteLogo.svg";
import {YOUTUBE_LINK, FACEBOOK_LINK, LINKEDIN_LINK, WHATSAPP_LINK, CHROME_EXTENSION_LINK, Routes, INSTAGRAM_LINK} from '../../../constants/routes'
import NewsLetter from "./NewsLetter/NewsLetter";
const socialLinks = [
  {
    id: 1,
    to: YOUTUBE_LINK,
    icon: <AiOutlineYoutube color="#ffffff"/>
  },
  {
    id: 2,
    to: FACEBOOK_LINK,
    icon: <CiFacebook color="#ffffff"/>
  },
  {
    id: 2,
    to: INSTAGRAM_LINK,
    icon: <CiInstagram color="#ffffff"/>
  },
  {
    id: 3,
    to: LINKEDIN_LINK,
    icon: <CiLinkedin color="#ffffff"/>
  },
  {
    id: 4,
    to: WHATSAPP_LINK,
    icon: <AiOutlineWhatsApp color="#ffffff"/>
  },
  {
    id: 5,
    to: CHROME_EXTENSION_LINK,
    icon: <AiOutlineChrome color="#ffffff"/>
  }
]

const linksData = [
  {
    to: "/",
    name: "Home",
  },
  {
    to: Routes.BLOGS,
    name: "Blogs",
  },
  {
    to: Routes.FAQS,
    name: "FAQs",
  },
];

const otherLinks = [
  {
    to: Routes.PRIVACY_POLICY,
    name: "Privacy Policy",
  },
  {
    to: Routes.TERMS,
    name: "Terms & Condition",
  },
  {
    to: Routes.CONTACT,
    name: "Contact Us",
  },
];

const date = new Date()

const Footer = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 868px)");
  const [isLargerThan450] = useMediaQuery("(min-width: 450px)");

  return (
    <>
      <Flex flexDir="column" align="center" w="100%" mt="275px" maxW="100%">
        <NewsLetter />
        <Flex
          bg="#0A1931"
          maxH={isLargerThan768 ? "100%" : "100%"}
          h={isLargerThan768 ? "" : "100%"}
          w="100%"
          pb={5}
        >
          <Flex
            w="100%"
            flexDir="column"
            mt={isLargerThan768 ? "250px" : "180px"}
            align="center"
          >
            <Flex w="70%" flexDir={isLargerThan768 ? "row" : "column"}>
              <Flex
                w={isLargerThan768 ? "50%" : "100%"}
                flexDir="column"
                align="flex-start"
                gap="20px"
              >
                <Image src={WhiteLogo} alt="white-logo" />
                <Text className="footer-text">
                SellerDash (Private) Limited is a powerful product hunting tool for Daraz & Lazada. With a wide array of features, SellerDash simplifies your product hunting operations, providing you with the tools you need to succeed in a highly competitive marketplace.
                </Text>
                <Flex gap={isLargerThan450 ? 5 : 3} flexWrap="wrap">
                  {
                    socialLinks?.map((link) => {
                      return (
                        <Link key={link?.to} to={link?.to} target="_blank">
                          <Flex className="circles">
                            {link?.icon}
                          </Flex>
                        </Link>
                      )
                    })
                  }
                  
                </Flex>
              </Flex>
              <Flex
                w={isLargerThan768 ? "50%" : "100%"}
                justify={isLargerThan768 ? "flex-end" : "flex-start"}
                gap={isLargerThan768 ? "70px" : "40px"}
                mt={isLargerThan768 ? "0px" : "20px"}
              >
                <Flex flexDir="column" gap="20px">
                  <Text color="#185ADB" fontSize="16px" fontWeight={600}>
                    Quick Links
                  </Text>
                  <Flex flexDir="column" gap="7px">
                    {linksData.map((data) => {
                      return (
                        <Link
                          key={data.name}
                          className="footer_link"
                          to={data.to}
                        >
                          {data?.name}
                        </Link>
                      );
                    })}
                  </Flex>
                </Flex>
                <Flex flexDir="column" gap="20px">
                  <Text color="#185ADB" fontSize="16px" fontWeight={600}>
                    Other Links
                  </Text>
                  <Flex flexDir="column" gap="7px">
                    {otherLinks.map((data) => {
                      return (
                        <Link
                          key={data.name}
                          className="footer_link"
                          to={data.to}
                        >
                          {data?.name}
                        </Link>
                      );
                    })}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Text
              align="center"
              color="#185ADB"
              fontSize="14px"
              fontWeight={400}
              mt={isLargerThan768 ? 55 : 30}
            >
              {date.getFullYear()} © SellerDash (Private) Limited - All Rights Reserved
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
export default Footer;
