import { useMutation } from "@tanstack/react-query"
import extensionUninstallFeedback from "services/extensionUninstallFeedback"
import { toast } from "react-hot-toast"

export const useExtensionUninstallFeedback = () => {
    const onSuccess = (res: any) => {
        toast.success("Thank you for providing feedback")
    }

    const onError = (error: any) => {
        toast.error(error?.response?.data?.message || "Something went wrong")
    }

    const { mutateAsync, isLoading, isError, error } = useMutation(["uninstall-feedback"], extensionUninstallFeedback, { onSuccess, onError });

    return { uninstallFeedback: mutateAsync, isLoading, isError, error };
}