import React from "react"
import { Route, Navigate } from "react-router-dom"
import FullScreenLoader from "./FullScreenLoader"
import { PERMISSIONS } from "enum/permissions"
import Permission from "./Permission"
import SocialVideos from "pages/SuperAdmin/SocialVideos/SocialVideos"

const AddEditBlog = React.lazy(() => import("pages/SuperAdmin/Blogs/AddEditBlog/AddEditBlog"))
const Subsciptions = React.lazy(() => import("pages/SuperAdmin/Subscriptions/Subsciptions"))
const AdminProducts = React.lazy(() => import("pages/SuperAdmin/Products/Products"))
const Coupons = React.lazy(() => import("pages/SuperAdmin/Coupons/Coupons"))
const Blogs = React.lazy(() => import("pages/SuperAdmin/Blogs/Blogs"))
const PaymentHistory = React.lazy(() => import("pages/SuperAdmin/PaymentHistory/PaymentHistory"))
const Roles = React.lazy(() => import("pages/SuperAdmin/Roles/Roles"))
const Admin = React.lazy(() => import("pages/SuperAdmin/Admin/Admin"))
const AdminDashboard = React.lazy(() => import("pages/SuperAdmin/Dashboard/AdminDashboard"))
const Users = React.lazy(() => import("pages/SuperAdmin/Users/Users"))
const Reports = React.lazy(() => import("pages/SuperAdmin/Reports/Reports"))
const Customers = React.lazy(() => import("pages/SuperAdmin/Customers/Customers"))
const MonthlySales = React.lazy(() => import("pages/SuperAdmin/MontlySales/MontlySales"))
const AdminProfile = React.lazy(() => import("pages/SuperAdmin/AdminProfille/Profile"))
const NotificatonManagement = React.lazy(() => import("pages/SuperAdmin/Notifications/Notification"))
const NotificatonLetter = React.lazy(() => import("pages/SuperAdmin/NewsLetter/NewsLetter"))
const Logs = React.lazy(() => import("pages/SuperAdmin/Log/Logs"))
const Faqs = React.lazy(() => import("pages/SuperAdmin/Faqs/Faqs"))
const Reviews = React.lazy(()=> import("pages/SuperAdmin/Reviews/Reviews"))
const AddEditReviews = React.lazy(()=> import("pages/SuperAdmin/Reviews/AddEditReviews"))
const WebsiteSettings = React.lazy(()=> import("pages/SuperAdmin/WebsiteSettings/WebsiteSettings"))
const AdminRoutes = (userPermission: PERMISSIONS[] | undefined, isLoadingUser: boolean) => {
    if (isLoadingUser) {
      return [];
    }

    if (userPermission?.some?.((permission: string | string[]) => permission.includes("ACCESS_ADMIN")))
    return [
          <Route key={"admin"} path="admin" element={<React.Suspense fallback={<FullScreenLoader />}><Admin /></React.Suspense>}>
            <Route key={"dashboard"} path="dashboard" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_DASHBOARD} Element={<AdminDashboard />} />} />
            <Route key={"users"} path="users" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_USER_MANAGEMENT} Element={<Users />} />} />
            <Route key={"reports"} path="reports" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_REPORTS} Element={<Reports />} />} />
            <Route key={"customers"} path="customers" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_CUSTOMERS} Element={<Customers />} />} />
            <Route key={"subsciptions"} path="subsciptions" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_SUBSCRIPTIONS} Element={<Subsciptions />} />} />
            <Route key={"sales"} path="sales" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_MONTHLY_SALES} Element={<MonthlySales />} />} />
            <Route key={"profile"} path="profile" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_DASHBOARD} Element={<AdminProfile />} />} />
            <Route key={"coupons"} path="coupons" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_COUPONS} Element={<Coupons />} />} />
            <Route key={"blogs"} path="blogs" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_BLOG_MANAGEMENT} Element={<Blogs />} />} />
            <Route key={"notifications"} path="notifications" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_NOTIFICATIONS_MANAGEMENT} Element={<NotificatonManagement />} />} />
            <Route key={"news-letter"} path="news-letter" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_NEWS_LETTER} Element={<NotificatonLetter />} />} />
            <Route key={"add-blog"} path="add-blog" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_BLOG_MANAGEMENT} Element={<AddEditBlog types="add" />} />} />
            <Route key={"blogs/:id"} path="blogs/:id" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_BLOG_MANAGEMENT} Element={<AddEditBlog types="edit" />} />} />
            <Route key={"products"} path="products" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_PRODUCTS} Element={<AdminProducts />} />} />
            <Route key={"payment-history"} path="payment-history" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_PAYMENT_HISTORY} Element={<PaymentHistory />} />} />
            <Route key={"roles"} path="roles" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_ROLES} Element={<Roles />} />} />
            <Route key={"logs"} path="logs" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_SUBSCRIPTION_UPDATE_LOGS} Element={<Logs />} />} />
            <Route key={"faq"} path="faq" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_FAQ_MANAGEMENT} Element={<Faqs />} />} />
            <Route key={"review"} path="review" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_REVIEW_MANAGEMENT} Element={<Reviews />} />} />
            <Route key={"review/add-edit"} path="review/add-edit" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_REVIEW_MANAGEMENT} Element={<AddEditReviews />} />} />
            <Route key={"videos"} path="social-videos" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_SOCIAL_VIDEOS_MANAGEMENT} Element={<SocialVideos />} />} />

            <Route path="website-settings" element={<Permission permission={PERMISSIONS.ACCESS_ADMIN_WEBSITE_SETTINGS} Element={<WebsiteSettings />} />} />
          </Route>
    ]
    return [
        <Route key={"admin/*"} path="admin/*" element={<Navigate to="/signin" />} />
    ]
};

export default AdminRoutes
