import { AES, enc, mode, pad, HmacSHA256 } from "crypto-js"

export const encrypt = (data: string) => {
  try {
    const key = enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY as string)
    const iv = enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_IV as string)
    const encryptedData = AES.encrypt(data, key, {
      mode: mode.CBC,
      padding: pad.Pkcs7,
      iv: iv,
    })
    return encryptedData.toString()
  }catch (error) {
    console.error(error)
    return {}
  }
}

export const decrypt = (encryptedData: string) => {
  try {
    const key = enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY as string)
    const iv = enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_IV as string)
    const decryptedData = AES.decrypt(encryptedData, key, {
      mode: mode.CBC,
      padding: pad.Pkcs7,
      iv: iv,
    }).toString(enc.Utf8)
    return JSON.parse(decryptedData)
  } catch (error) {
    console.error(error)
    return {}
  }
}

export function generateSignature(apiName: string, parameters: Record<string, string | number | undefined | boolean>): string {
  const sortedParameters = Object.entries(parameters)
    .sort(([a], [b]) => a.localeCompare(b))
    .map(([key, value]) => `${key}${value}`)
    .join('');
  const concatenatedString = `${apiName}${sortedParameters}`;
  const hmacDigest = HmacSHA256(concatenatedString, process.env.DARAZ_API_SECRET as string);
  const hex = enc.Hex.stringify(hmacDigest)
  return hex;
}