import { FlexProps, TextProps, ButtonProps} from '@chakra-ui/react';

export const ModalWrapper : FlexProps = {
    h:"auto",
    bg:"#FFFFFF",
    borderRadius:"12px",
    flexDirection:"column",
    justifyContent:"center",
    align:"center",
    gap:"15px" 
}
export const Title : TextProps = {
    fontSize:"24px",
    fontWeight:700
}
export const FormWrapper : FlexProps = {
    flexDirection:"column",
    justifyContent:"space-between",
    h:"100%"
}
export const Inner : FlexProps = {
    flexDirection:"column",
    gap:"21px",
    w:"90%",
    marginBottom:"10px"
}
export const InputWrapper : FlexProps = {
    gap: "10px",
    flexDirection: "column"
}
export const Space : FlexProps = {
    justifyContent:"space-between"
}
export const TextOne : TextProps = {
    mt:"10px", fontWeight: 700,
    textTransform: 'capitalize'
}
export const TextTwo : TextProps = {
    fontSize:"14px", fontWeight: 400
}
export const DownloadBtn : ButtonProps = {
    variant:"primary", w:"200px", h:"40px", mt:"30px", fontSize:"14px", fontWeight: 500
}