
interface IText {
    type: any
    className?: string
    children?: React.ReactNode | React.ReactNode[] | undefined;
}
const Text:React.FC<IText> = ({ type, className, children }: IText) => {
    const Element = type ?? "span"
    return (
        <Element
            className={className}

        >
            {children}
        </Element>
    )
}

export default Text