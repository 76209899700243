import { Button, ButtonProps, CircularProgress } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React from 'react'

interface Props {
    text?: string;
    unstyled?: boolean;
}

const FormButton: React.FC<Props & ButtonProps> = ({
    text,
    disabled,
    size = "md",
    color = 'white',
    unstyled,
    isLoading,
    ...rest
}) => {
    const { handleSubmit } = useFormikContext();
    if (unstyled) return <Button size={size} color={color} disabled={disabled} {...rest}>{text}</Button>
    return (
        <Button
            color={color}
            size={size}
            isDisabled={disabled}
            // @ts-ignore
            onClick={handleSubmit} {...rest}>
              {isLoading ?  <CircularProgress ml={1} size={5} color="blackAlpha.900" isIndeterminate /> : text }
        </Button>
    )
}

export default FormButton