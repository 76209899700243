import UserDashbaordLayout from "layouts/UserDashbaordLayout";
import Transition from "components/Transition";
import MaintenanceMode from "components/MaintenanceMode/MaintenanceMode";
import ProductDiscoveryInner from "./ProductDiscoveryLayout";

const ProductDiscovery = () => {
    
    const isMaintenanceMode = false; // This could be determined dynamically

    return (
        <Transition>
            <UserDashbaordLayout title="Product Discovery - Sellerdash">
                {!isMaintenanceMode ? 
                (
                    <ProductDiscoveryInner />
                )
                :   <MaintenanceMode />
                }
            </UserDashbaordLayout>  
        </Transition>             
    )
};

export default ProductDiscovery;
