import axios from "axios";
import { Apis } from "constants/apis";
import { ICollectionProduct } from "types/services";
import { getTimeDifference } from "utils/getTimeDifference";
import { getToken } from "utils/token";


const getDashboardProducts = async (payload: any) => {
    const { data } = await axios.get(Apis.GET_DASHBOARD_PRODUCTS, { headers: { Authorization: getToken() } })
    const products = data.map((product: ICollectionProduct) => ({
        ...product,
        added: getTimeDifference(product?.updatedAt || product?.createdAt),
    }));
    return products;
}

export default getDashboardProducts