import axios from 'axios';
import { Apis } from 'constants/apis';
import { getToken } from 'utils/token';

const createSocialVideo = async (payload: any) => {
    const { data } = await axios.post(Apis.CREATE_SOCIAL_VIDEOS, payload, { headers: { Authorization: getToken() } });
    return data;
};

export default createSocialVideo;
