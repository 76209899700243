import axios from "axios";
import { Apis } from "constants/apis";
import { IGetAllProductsPayload } from "types/globals";
import { getToken } from "utils/token";


const GetProducts = async (payload?: IGetAllProductsPayload) => {
    let queryParams = `?`;
    queryParams += payload?.skip && payload?.limit ? `&skip=${payload.skip}&limit=${payload.limit}` : '';
    queryParams += payload?.searchText ? `&searchText=${payload.searchText}` : '';
    queryParams += payload?.minPrice && payload?.maxPrice ? `&minPrice=${payload.minPrice}&maxPrice=${payload?.maxPrice}` : '';

    const { data } = await axios.get(`${Apis.GET_PRODUCTS}${queryParams}`, { headers: { Authorization: getToken() } })
    return data
}

export default GetProducts