import React from 'react'
import { Center, Image } from '@chakra-ui/react'
import Transition from './Transition'
import loader from "assets/loader.gif"

const FullScreenLoader: React.FC = () => {
    return (
        <Transition>
            <Center w="100%" h="100vh" maxH="100vh">
                <Image src={loader} width="60px" height="60px" />
            </Center>
        </Transition>
    )
}

export default FullScreenLoader