import axios from "axios"
import { Apis } from "constants/apis"
import { RefreshService } from "types/services"
import { getToken } from "utils/token"

const refresh: RefreshService = async() => {
    const { data } = await axios.get(Apis.REFRESH, { headers: { Authorization: getToken() } })
    return data
}

export default refresh