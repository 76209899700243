import { TGetProp } from "types/helper"

export const getKey: TGetProp = (object: object) => object ? Object.keys(object)[0] : ''
export const getValue: TGetProp = (object: object) => object ? Object.values(object)[0] : ''

export const formatRelativeTime = (timestamp: string): string => {
    const date = new Date(timestamp);
    const now = new Date();
    let differenceInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    differenceInSeconds += 4 * 3600
    if (differenceInSeconds < 60) {
        return `${differenceInSeconds} seconds ago`;
    } else if (differenceInSeconds < 3600) {
        const minutes = Math.floor(differenceInSeconds / 60);
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (differenceInSeconds < 86400) {
        const hours = Math.floor(differenceInSeconds / 3600);
        return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
        const days = Math.floor(differenceInSeconds / 86400);
        return `${days} day${days !== 1 ? 's' : ''} ago`;
    }
};