import {  useToast } from "@chakra-ui/react"
import { useQueryClient, useMutation , useQuery} from "@tanstack/react-query"
import createNotification from "services/Admin/Notifications/createNotification"
import deleteNotification from "services/Admin/Notifications/deleteNotification"
import updateNotification from "services/Admin/Notifications/updateNotification"
import getAllNotifications from "services/Admin/Notifications/getAllNotification"
import getNotificationById from "services/Admin/Notifications/getNotificationById"
import { INotificationResponse, IPaginationPayload } from "types/services"

export const useCreateNotification = (payload: IPaginationPayload) =>{
    const toast = useToast()
    const queryClient = useQueryClient()

    const onSuccess = () => {
        toast({title:"Notification created!!", status:"success"})
        queryClient.invalidateQueries(['getAllNotification', payload]);
    }

    const onError = (err: any) => {
        toast({ title: err?.response?.data?.message || err?.response?.data || "Something went wrong", status: "error" });
    }

    const {mutateAsync, isLoading} = useMutation(createNotification, { onSuccess, onError })
    return { createNotification: mutateAsync, creatingNotification: isLoading }
}

export const useGetAllNotifications = (payload: IPaginationPayload) => {
    const toast = useToast();

    const onError = (err: any) => {
        toast({ title: err?.response?.data?.message || err?.response?.data || "Something went wrong", status: "error" });
    };
  
    const {  data, isLoading, isFetching } = useQuery(
      ["getAllNotification", payload],
      () => getAllNotifications(payload),
      { refetchOnWindowFocus: false, onError }
    );
  
    return { notifications: data?.data, total: data?.total, loadingNotifications: isLoading, fetchingNotifications: isFetching };
};

export const useGetNotificationById= (id: number) => {
    const toast = useToast();

    const onError = (err: any) => {
        toast({ title: err?.response?.data?.message || err?.response?.data || "Something went wrong", status: "error" });
    };
  
    const {  data, isLoading, isFetching } = useQuery(
      ["getNotification", id],
      () => getNotificationById(id),
      { refetchOnWindowFocus: true, onError }
    );
  
    return { notification: data, loadingNotification: isLoading, fetchingNotification: isFetching };
};

export const useUpdateNotification = (payload: IPaginationPayload) => {
    const toast = useToast();
    const queryClient = useQueryClient()
    
    const onSuccess = (notification: INotificationResponse) => {
        toast({ title: "Notification Updated Successfully", status: "success" });
        queryClient.invalidateQueries(['getAllNotification', payload]);
    }
    
    const onError = (err: any) => {
        toast({ title: err?.response?.data?.message || err?.response?.data || "Something went wrong", status: "error" });
    };

    const {mutateAsync, isLoading} = useMutation(updateNotification,{onSuccess, onError});
    
    return { updateNotificationById: mutateAsync, isUpdationgNotification: isLoading }
}

export const useDeleteNotificationById = (payload: IPaginationPayload) => {
    const toast = useToast();
    const queryClient = useQueryClient()

    const onSuccess = () => {
        toast({ title: "Notification deleted!", status: "success" });
        queryClient.invalidateQueries(['getAllNotification', payload]);
    };

    const onError = (err: any) => {
        toast({ title: err?.response?.data?.message || err?.response?.data || "Something went wrong", status: "error" });
    };

    const { mutate, isLoading } = useMutation(deleteNotification, {
        onSuccess,
        onError,
    });

    return {  deleteNotificationById: mutate, deletingNotification: isLoading };
}