import React from 'react'

interface Props {
    fill?: string;
    h?: number;
    w?: number;
}

const StarRatingIcon: React.FC<Props> = ({ fill = "#faaf00", h = 20, w = 20 }) => {
  return (
    <svg  fill={fill} height={h} width={w} viewBox="0 0 20 20">
        <path d="M11.128 4.123c-.453-.95-1.803-.95-2.256 0l-1.39 2.912-3.199.421c-1.042.138-1.46 1.422-.697 2.146l2.34 2.222-.587 3.172c-.192 1.034.901 1.828 1.825 1.327l2.836-1.54 2.836 1.54c.924.501 2.017-.293 1.825-1.327l-.587-3.172 2.34-2.222c.762-.724.345-2.008-.697-2.146l-3.2-.421-1.389-2.912Z"></path>
    </svg>
  )
}

export default StarRatingIcon