import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { TbLogout } from "react-icons/tb";
import { RxAvatar } from "react-icons/rx";
import { useLogout, useSubscription, useUser } from "hooks/user";
import { useNavigate } from "react-router-dom";
import { BsArrowUpCircle } from "react-icons/bs";
import NavDrawer from "./Drawer/NavDrawer";
import DataUsage from "components/DataUsage/DataUsage";
import { ROLES } from "enum/roles";
import { PlanType } from "enum/planTypes";

const UserNav = () => {
  const { logout } = useLogout();
  const navigate = useNavigate();
  const [isLargerThan1140] = useMediaQuery("(min-width: 1140px)");
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { user } = useUser();
  const { subscription } = useSubscription();

  const handleProfile = () => {
    navigate("profile");
  };

  const handleAdminNavigate = () => {
    navigate("/admin/dashboard");
  };

  return (
    <>
      <Flex
        bg="#FFFFFF"
        boxShadow="0px 1px 4px rgba(0, 0, 0, 0.15)"
        py={15}
        w="100%"
        mb={10}
        justify={isLargerThan1140 ? "flex-end" : "space-between"}
        align="center"
        pl="30px"
        pr="30px"
        gap="30px"
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          width: "100%",
          zIndex: "100",
        }}
      >
        {!isLargerThan1140 && <NavDrawer key={"nav2"} />}
        {user?.role === ROLES.SUPER_ADMIN && (
          <>
            <Button
              variant="primary"
              w="160px"
              h="35px"
              gap={2}
              cursor="pointer"
              onClick={handleAdminNavigate}
              display={isLargerThan768 ? "flex" : "none"}
            >
              <Text fontSize="14px">Admin Dashboard</Text>
            </Button>
          </>
        )}
        <Flex gap={4}>
          {subscription?.planType === PlanType.FREE ? <Button
            variant="primary"
            w="125px"
            h="35px"
            gap={2}
            cursor="pointer"
            onClick={() => navigate("billing")}
            display={isLargerThan768 ? "flex" : "none"}
          >
            <BsArrowUpCircle />
            <Text fontSize="14px">Upgrade</Text>
          </Button> : <></>}
          <Flex gap={5}>
            <DataUsage />
            <Box borderRight="1px solid #AEAEAE"></Box>
          </Flex>
          <Menu>
            <MenuButton>
              <RxAvatar color="#656262" fontSize="40px" />
            </MenuButton>
            <MenuList style={{ width: "130px" }}>
              <MenuItem onClick={handleProfile} 
                     _focus={{ 
                      bg: "transparent",
                      boxShadow: "none"  
                    }} 
                    _hover={{ 
                      bg: "#fff", 
                      boxShadow: "none" 
                    }}
              >
                <Flex mx={2} align="center" gap={2} cursor="pointer">
                  <RxAvatar color="#656262" fontSize="17px" />
                  <Text
                    fontSize="14px"
                    fontWeight={500}
                    color="#656262"
                    _hover={{ color: "#185ADB" }}
                  >
                    Settings
                  </Text>
                </Flex>
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => logout().catch()} 
                      _focus={{ 
                        bg: "transparent",
                        boxShadow: "none"  
                      }} 
                      _hover={{ 
                        bg: "#fff", 
                        boxShadow: "none" 
                      }}
              >
                <Flex ml={2} align="center" gap={2} cursor="pointer">
                  <TbLogout color="#656262" fontSize="17px" />
                  <Text
                    fontSize="14px"
                    fontWeight={500}
                    color="#656262"
                    _hover={{ color: "#185ADB" }}
                  >
                    Log Out
                  </Text>
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </>
  );
};
export default UserNav;
