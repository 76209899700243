import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';
import BlogProvider from 'pages/SuperAdmin/Blogs/context/BlogContext';
import theme from './theme';
import AppProvider from 'global/AppContext';
import axios from "axios"

import './index.scss';
import BillingProvider from 'pages/UserDashboard/Billing/context/BillingContext';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <QueryClientProvider client={client}>
        <BrowserRouter>
          <ChakraProvider theme={theme}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string} >
            <AppProvider>
                <BillingProvider>
                  <BlogProvider>
                      <App key="main_app" />
                  </BlogProvider>
                </BillingProvider>
                </AppProvider>
            </GoogleOAuthProvider>
          </ChakraProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
  </React.StrictMode>
);
