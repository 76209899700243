import { FC, useEffect, useState } from 'react';
import { Checkbox, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Text } from '@chakra-ui/react';
import FormButton from 'components/Form/Button/FormButton';
import Form from 'components/Form/Form';
import { useCreateSocialVideo, useUpdateSocialVideo } from 'hooks/socialVideos';
import { createSocialVideoSchema, updateSocialVideoSchema } from 'schemas/socialVideo';
import TextAreaInput from 'components/Form/TextAreaInput/TextAreaInput';
import TextInput from 'components/Form/TextInput/TextInput';

interface ISocialVideo {
    onClose: () => void;
    isOpen: boolean;
    socialVideoToEdit?: any;
    type: string;
}

const AddUpdateSocialVideo: FC<ISocialVideo> = ({ onClose, isOpen, socialVideoToEdit, type }) => {
    const { createSocialVideo, isLoading: isCreatingSocialVideo } = useCreateSocialVideo();
    const { updateSocialVideo, isLoading: isUpdatingSocialVideo } = useUpdateSocialVideo();
    const [isActive, setIsActive] = useState(socialVideoToEdit?.active);
    useEffect(() => setIsActive(socialVideoToEdit?.active), [socialVideoToEdit]);
    const handleAddUpdateSocialVideo = async (values: any, { resetForm }: any) => {
        const payload = { ...values, active: isActive };
        try {
            if (type === 'add') {
                await createSocialVideo(payload);
            } else {
                await updateSocialVideo(payload);
            }
        } catch (err) {
        } finally {
            resetForm();
            onClose();
        }
    };

    const formInitialValues = {
        id: type !== 'add' ? socialVideoToEdit?.id : null,
        heading: type !== 'add' ? socialVideoToEdit?.heading : '',
        description: type !== 'add' ? socialVideoToEdit?.description : '',
        link: type !== 'add' ? socialVideoToEdit?.link : '',
        sortedIndex: type !== 'add' ? socialVideoToEdit?.sortedIndex : 0,
        active: type !== 'add' ? socialVideoToEdit?.active : 0,
    };
    return (
        <>
            <Drawer isOpen={isOpen} onClose={onClose} size="md">
                <DrawerOverlay />
                <DrawerContent overflowY={"auto"}>
                    <DrawerCloseButton />
                    <DrawerHeader>{type === 'add' ? 'Add Social Video' : 'Update Social Video'}</DrawerHeader>
                    <Form
                        initialValues={formInitialValues} 
                        validationSchema={type === 'add' ? createSocialVideoSchema : updateSocialVideoSchema} 
                        onSubmit={handleAddUpdateSocialVideo} 
                    >
                        <DrawerBody overflow="none">
                            <Flex flexDirection="column" gap={10}>
                                <Flex flexDir="column" gap={2}>
                                    <Text color="#0A1931" fontSize="14px" fontWeight={400}>
                                        Heading
                                    </Text>
                                    <TextAreaInput size="lg" placeholder="Enter heading" fieldName="heading" />
                                </Flex>
                                <Flex flexDir="column" gap={2}>
                                    <Text color="#0A1931" fontSize="14px" fontWeight={400}>
                                        Description
                                    </Text>
                                    <TextAreaInput size="lg" placeholder="Enter description" fieldName="description" />
                                </Flex>
                                <Flex flexDir="column" gap={2}>
                                    <Text color="#0A1931" fontSize="14px" fontWeight={400}>
                                        Link
                                    </Text>
                                    <TextInput size="lg" placeholder="Enter link" fieldName="link" />
                                </Flex>
                                <Flex flexDir="column" gap={2}>
                                    <Text color="#0A1931" fontSize="14px" fontWeight={400}>
                                        Sorted Index
                                    </Text>
                                    <TextInput type="number" size="lg" placeholder="Enter sorted index" fieldName="sortedIndex" />
                                </Flex>
                                <Flex flexDir="column" gap={2}>
                                    <Checkbox colorScheme="blue" isChecked={isActive} onChange={() => setIsActive(!isActive)}>
                                        <Text color="#0A1931" fontSize="14px" fontWeight={400}>
                                            Active
                                        </Text>
                                    </Checkbox>
                                </Flex>
                            </Flex>
                            <FormButton
                                type="submit"
                                text={type === 'add' ? 'Add' : 'Update'}
                                h="45px"
                                w="25%"
                                mt={10}
                                bg="#185ADB"
                                fontSize="14px"
                                disabled={type === 'add' ? isCreatingSocialVideo : isUpdatingSocialVideo}
                                isLoading={type === 'add' ? isCreatingSocialVideo : isUpdatingSocialVideo}
                            />
                        </DrawerBody>
                    </Form>
                </DrawerContent>
            </Drawer>
        </>
    );
};
export default AddUpdateSocialVideo;
