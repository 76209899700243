import React from 'react'
import { Route, Navigate } from "react-router-dom"
import FullScreenLoader from './FullScreenLoader'

const Verify = React.lazy(() => import("pages/Auth/Verify/Verify"))

const NotVerifiedRoutes = (userId?: number, status?: boolean) => {

  if(userId && !status)
  return [
        <Route key={"verify2"} path="verify" element={<React.Suspense fallback={<FullScreenLoader />}><Verify /></React.Suspense>} />
  ]

  return [
      <Route key={"verify"} path="verify" element={<Navigate to="/user/dashboard" />} />
  ]
}

export default NotVerifiedRoutes