import axios from "axios";
import { Apis } from "constants/apis";
import { TGetSubscriptionService } from "types/services";
import { decrypt } from "utils/security";
import { getToken } from "utils/token";

const getSubscription: TGetSubscriptionService = async() => {
    const { data } = await axios.get(Apis.GET_MY_SUBSCRIPTION, { headers: { Authorization: getToken() } });
    return decrypt(data?.data);
}

export default getSubscription