import axios from "axios";
import { Apis } from "constants/apis";
import { decrypt } from "utils/security";
import { getToken } from "utils/token";

const getStripeInvoice = async () => {
    const { data } = await axios.get(Apis.GET_STRIPE_INVOICE, { headers: { Authorization: getToken() } })
    return decrypt(data?.data);
}

export default getStripeInvoice