import ImageCard from "components/ImageCard/ImageCard"
import { CardImage } from "types/globals"


interface CardList {
    features: CardImage[]
}

const Features:React.FC<CardList> = ({ features }) => {
    return (
        <>
            {features.map((card: CardImage) => (
                <ImageCard key={card.heading} {...card} />
            ))}
        </>
    )
}

export default Features