import { IconBaseProps } from 'react-icons';
import { CenterProps, FlexProps, TextProps} from "@chakra-ui/react";

export const Wrapper : FlexProps = {
    flexDir:"column"
}
export const NavWrapper : FlexProps= {
    justifyContent:"space-between", align:"center"
}
export const MainWrapper: CenterProps = {
    h:"100vh",
}
export const InnerWrapper: FlexProps = {
    boxShadow:"0px 4px 10px rgba(0, 0, 0, 0.25)",
    bg:"#fff"
}
export const gap50 : FlexProps = {
    gap:"50px"
}
export const navText : TextProps = {
    color:"#fff", fontSize:"16px", fontWeight:500, cursor:"pointer"
}
export const ContentWrapper: FlexProps = {
    w:"567px", bg:"#F6F6F6", py:"24px", flexDirection:"column"
}
export const MainText: TextProps = {
  fontSize:"15px", fontWeight:600, color: "#FFF"
}
export const FieldWrapper: CenterProps = {
    flexDirection:"column", mt:"15px"
}
export const WelcomeText : TextProps = {
    fontSize:"26px", fontWeight:700
}
export const ContinueText : TextProps = {
    fontSize:"14px", fontWeight:400, mt:"5px"
}
export const GoogleBtnWrapper : FlexProps = {
    justify:"center", mt:"27px"
}
export const GoogleIcon : IconBaseProps = {
    fontSize:"30px"
}
export const CenterItems : CenterProps = {
    flexDir:"column", gap:"10px"
}
export const RememberText : TextProps = {
    fontSize: "14px", fontWeight:500
}
export const GapWorries: FlexProps = {
  gap: "5px",
};
export const Worries: TextProps = {
  color: "secondary",
  fontSize: "14px",
  fontWeight: 400,
};
export const WorriesEmail: TextProps = {
  color: "secondary",
  fontSize: "14px",
  fontWeight: 600,
};
export const Resend: FlexProps = {
  w: "100%",
  gap: "5px",
  cursor: "pointer",
  align: "center",
  fontSize: "15px",
  display: "flex",
  fontWeight: 600,
};
export const ForgotText : TextProps ={
    w:"100%",
    textAlign:"right",
    cursor:"pointer",
    color:"#185ADB",
    fontSize: "14px",
    fontWeight:500,
}
export const GoogleBtn: CenterProps = {
    borderRadius: "6px",
    cursor: "pointer",
    flexDir: "row",
    gap: "16px",
    h: "50px",
    border: "1px solid #CDCDCD",
    bg:"#fff"
  };
  export const GoogleBtnText: TextProps = {
    fontSize: "18px",
    fontWeight: "600",
  };
  export const EmailTextWrapper: FlexProps = {
    flexDir: "row",
    gap: "10px",
    justify: "space-between",
    align: "center",
    mt:"27px"
  };
  export const EmailLine: CenterProps = {
    flex: 1,
    h: "1px",
    bgColor: "#AEAEAE",
    alignItems: "center",
    marginTop: "11px",
  };
  
  export const EmailText: TextProps = {
    textAlign: "center",
    color: "#AEAEAE",
    flex: 1,
    fontSize:"14px"
  };
  export const FormWrapper: FlexProps = {
    flexDir: "column",
    gap: "20px",
    align: "center",
    mt: "20px"
  };
  export const TextInputWrapper: FlexProps = {
    w: "100%",
    flexDir: "column",
    gap: "8px",
  };
  export const InputLabel: TextProps = {
    fontSize: "14px",
    fontWeight: 400,
  };
  export const ExtraBtn: FlexProps = {
    w: "100%",
    justify: "space-between",
  };
  export const CheckboxWrapper: FlexProps = {
    gap: "5px",
    fontWeight: "600",
    fontSize: "18px",
    w: "100%",
  };
  
  export const Forgot: TextProps = {
    w: "100%",
    textAlign: "center",
    cursor: "pointer",
    color: "185ADB",
    fontWeight: 600,
  };
  
  export const SubmitWrapper: FlexProps = {
    gap: "0px",
    w: "100%",
    flexDir: "column",
    justifyContent:"flex-start"
  };
  export const BackWrapper: FlexProps = {
    w: "100%",
    gap: "5px",
    cursor: "pointer",
    align: "center",
  };
  export const IconBack: IconBaseProps = {
    color: "#185ADB",
    fontSize: "30px",
  };
  export const BackText: TextProps = {
    fontSize: "14px",
    fontWeight: "600",
    color: "#185ADB",
  };
  