import {
    FormControl,
    FormErrorMessage,
    InputGroup,
    InputRightElement,
    Button,
    Textarea,
  } from "@chakra-ui/react";
  import { useFormikContext } from "formik";
  import useColors from "hooks/useColors";
  import React, { useState } from "react";
  import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
  import * as styles from "./styles"
  
  export interface Props {
    placeholder: string;
    fieldName: string;
    size?: string;
    disabled?: boolean;
    secureTextEntry?: boolean;
    customStyles?: any;
    className?: string;
    type?: string;
  }
  
  const TextAreaInput: React.FC<Props> = ({
    placeholder,
    fieldName,
    type,
    size = "md",
    disabled = false,
    secureTextEntry,
    customStyles,
    className,
  }) => {
    const { values, touched, errors, handleChange, setFieldTouched } =
      useFormikContext<any>();
    const [showText, setShowText] = useState(!secureTextEntry);
    const { primaryColor } = useColors();
    const handleShowHideClick = () => setShowText((prev) => !prev);
  
    const ShowHideInputButton = () => (
      <InputRightElement {...styles.InputRight}>
        <Button
          {...styles.ShowBtn}
          onClick={handleShowHideClick}>
          {showText ? (
            <AiFillEye fontSize="25px" color={primaryColor}/>
          ) : (
            <AiFillEyeInvisible fontSize="25px" color={primaryColor} />
          )}
        </Button>
      </InputRightElement>
    );
  
    const isError = !!(touched[fieldName] && errors[fieldName]);
  
    return (
      <FormControl pos="relative" isInvalid={isError}>
        <InputGroup {...styles.InputWrapper}>
          <Textarea
            placeholder={placeholder}
            type={type || (showText ? "text" : "password")}
            size={size}
            {...styles.TextInputField}
            disabled={disabled}
            onChange={handleChange(fieldName)}
            onBlur={() => setFieldTouched(fieldName)}
            {...customStyles}
            className={className}
            autoComplete="false"
            rows={5}
            value={values[fieldName]}
            resize="none"
          />
          {secureTextEntry && <ShowHideInputButton />}
        </InputGroup>
        <FormErrorMessage>
          {errors[fieldName] as React.ReactNode}
        </FormErrorMessage>
      </FormControl>
    );
  };
  
  export default TextAreaInput;
  