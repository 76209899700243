import React from 'react'
import { Center, Image } from '@chakra-ui/react'
import Transition from './Transition'
import loader from "assets/loader.gif"

const UserPageLoader: React.FC = () => {
    return (
        <Center w="100%" h="100vh">
            <Image src={loader} width="60px" height="60px" />
        </Center>
    )
}

export default UserPageLoader