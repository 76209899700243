import UserDashbaordLayout from "layouts/UserDashbaordLayout";
import Transition from "components/Transition";
import MaintenanceMode from "components/MaintenanceMode/MaintenanceMode";
import StoreDiscoveryInner from "./StoreDiscoveryLayout";

const StoreDiscovery = () => {
    
    const isMaintenanceMode = false; // This could be determined dynamically

    return (
        <Transition>
            <UserDashbaordLayout title="Store Discovery - Sellerdash">
                {!isMaintenanceMode ? 
                (
                    <StoreDiscoveryInner />
                )
                :   <MaintenanceMode />
                }
            </UserDashbaordLayout>  
        </Transition>             
    )
};

export default StoreDiscovery;
