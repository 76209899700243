import { Box, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import Logo from "assets/svgs/nav-icon.svg";
import { Routes } from "constants/routes";
import NavDrawer from "./Drawer/NavDrawer";
import { NavLink, useLocation } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { useUser } from "hooks/user";
import { motion } from "framer-motion";

import "./nav.scss";
import { INotificationResponse } from "types/services";
import { useGetAllNotifications } from "hooks/notifications";
import { CustomNotificationLocation } from "enum/notifications";

interface Props {
  hide?: boolean;
}

const Navbar: FC<Props> = ({ hide }) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isLargerThan980] = useMediaQuery("(min-width: 980px)");
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
  const location = useLocation();

  const { user } = useUser();
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const { notifications } = useGetAllNotifications({page: 1, limit: 100});
  const [ actveNotification, setActiveNotification ] = useState<INotificationResponse>();

  const links: any[] = [
    { to: Routes.PRICING, name: "Pricing" },
    { to: Routes.CALCULATOR, name: "Calculator" },
    { to: Routes.CONTACT, name: "Contact Us" },
  ];

  const moreLinks = [
    { to: "/#features", name: "Features", section: "#features" },
    { to: "/#countries", name: "Countries", section: "#countries" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = currentScrollPos > prevScrollPos;

      if (isScrollingDown && isNavbarVisible) {
        setIsNavbarVisible(false);
      } else if (!isScrollingDown && !isNavbarVisible) {
        setIsNavbarVisible(true);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, isNavbarVisible]);

  useEffect(() => {
    if (!!notifications?.length) {
      setActiveNotification(
        notifications.find((notification: INotificationResponse) =>
          !!notification?.status &&
          (
            !!notification?.location?.includes(CustomNotificationLocation.PUBLIC_PAGES) ||
            notification?.location?.some(notifilocation => location.pathname.startsWith(notifilocation))
          )
        )
      );

    }
  }, [notifications, notifications?.length, location.pathname]);
  

  return (
    <motion.div
      className="nav-wrapper"
      initial={{ y: 0 }}
      animate={{ y: isNavbarVisible ? 0 : -100 }}
      transition={{ duration: 0.3 }}
      style={{...(actveNotification && { top: "40px" })}}
    >
      <NavLink to="/">
        <Image src={Logo} alt="logo" />
      </NavLink>
      <Box
        display={isLargerThan980 ? "flex" : "none"}
        gap={isLargerThan1280 ? "40px" : "20px"}
      >
        {moreLinks?.map((data, idx) => (
          <NavLink key={idx} to={data?.to} className="link-text">
            {data?.name}
          </NavLink>
        ))}
        {links?.map((data) => {
          return (
            <NavLink key={data.to} to={data?.to}>
              <Text className="link-text" cursor="pointer">
                {data?.name}
              </Text>
            </NavLink>
          );
        })}
      </Box>
      {user?.id ? (
        <Box
          gap={isLargerThan1280 ? "40px" : "20px"}
          display={isLargerThan768 ? "flex" : "none"}
        >
          <NavLink to={Routes.DASHBOARD}>
            <Flex
              justify="center"
              align="center"
              w="130px"
              h="40px"
              bg="#185ADB"
              borderRadius="4px"
              border="1px solid #185ADB"
            >
              <Text className="auth-btn" cursor="pointer">
                Dashboard
              </Text>
            </Flex>
          </NavLink>
        </Box>
      ) : (
        <Box
          gap={isLargerThan1280 ? "40px" : "20px"}
          display={isLargerThan768 ? "flex" : "none"}
        >
          <NavLink to={Routes.SIGNIN}>
            <Flex
              justify="center"
              align="center"
              w="100px"
              h="40px"
              border="1px solid #111"
              bg="#fff"
              borderRadius="4px"
            >
              <Text
                className="auth-btn"
                cursor="pointer"
                color="black !important"
              >
                Signin
              </Text>
            </Flex>
          </NavLink>
          <NavLink to={Routes.SIGNUP}>
            <Flex
              justify="center"
              align="center"
              w="100px"
              h="40px"
              bg="#185ADB"
              borderRadius="4px"
              border="1px solid #185ADB"
            >
              <Text className="auth-btn" cursor="pointer">
                Signup
              </Text>
            </Flex>
          </NavLink>
        </Box>
      )}
      {isLargerThan768 ? "" : <NavDrawer key={"nav1"} />}
    </motion.div>
  );
};

export default Navbar;
