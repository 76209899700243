
import { Image } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { CardImage } from "types/globals"
import "./styles.scss"
const ImageCard:React.FC<CardImage> = ({ heading, image, description, btnText, btnLink, direction = 'left' }) => {
    return (
        <div className="imageCardContainer">
            <div className="cardImage">
                <Image width="100%" height="100%" src={image} />
            </div>
            <div className="imageCardContent">
                <h3 className="cardHeading">{heading}</h3>
                <p className="cardDescription">{description}</p>
                {btnLink && (
                    <Link to={btnLink} className="cardBtn">{btnText}</Link>
                )}
            </div>
        </div>
    )
}

export default ImageCard