import "./compaign.scss"
import HomeLayout from "layouts/HomeLayout";
import Transition from "components/Transition";
import Features from "./Features";
import store_insights_img from "assets/img/store-insights.png";

const features = [
    {
        image: store_insights_img,
        heading: 'Your roadmap to selling on Amazon is here.',
        description: 'Get all the training and tools you need to start and grow your business on Amazon with Freedom Ticket and Helium 10.',
        btnText: "Get Started",
        btnLink: "/signup"
    },
    {
        image: store_insights_img,
        heading: 'Your roadmap to selling on Amazon is here.',
        description: 'Get all the training and tools you need to start and grow your business on Amazon with Freedom Ticket and Helium 10.',
        direction: "right"
    },
    {
        image: store_insights_img,
        heading: 'Your roadmap to selling on Amazon is here.',
        description: 'Get all the training and tools you need to start and grow your business on Amazon with Freedom Ticket and Helium 10.'
    }
]
const Compaigns = () => {
    return (
        <Transition>
            <HomeLayout title="Contact Us - SellerDash">
                <Features features={features} />
            </HomeLayout>
        </Transition>
    )
}
export default Compaigns