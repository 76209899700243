import axios from "axios";
import { getToken } from "utils/token";


const removeProduct = async (payload: any) => {
    try {
        const { data } = await axios.delete(`collectionProducts/removeProduct?itemId=${payload.itemId}&&collectionId=${payload.collectionId}`, { headers: { Authorization: getToken() } })
        return data
    } catch (err) {
        
    }
}

export default removeProduct