import { Modals } from "constants/modals";
import React, { createContext, useState } from "react"
import { IAppContext, IAppState } from "types/globals";

export const AppContext = createContext<IAppContext>({})

interface Props {
    children?: React.ReactNode | React.ReactNode[];
}

const AppProvider: React.FC<Props> = ({ children }) => {
    const [appState, setAppState] = useState<IAppState>({})
    const [productPayload, setProductPayload] = useState<any>({})

    const openModal = (modal: Modals) => setAppState({ ...appState, modal })

    const closeModal = () => setAppState({ ...appState, modal: null })

    return (
        <AppContext.Provider value={{ appState, setAppState, openModal, closeModal, setProductPayload, productPayload }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider