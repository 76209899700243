import { useQuery } from "@tanstack/react-query"

interface IConfirmationModal {
    heading?: string;
    description?: string;
    reason?: any,
    onYesCallback?: (...args: any[]) => any;
    onNoCallback?: (...args: any[]) => any;
}

const getData = async (): Promise<IConfirmationModal> => {
    return {}
}

export const useConfirmationModal = () => {
    const { data } = useQuery(["confirmationModal"], getData)
    return { ...data }
}