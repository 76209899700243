export function getTimeDifference(timestamp: string) {
  const now = new Date();
  const updatedTime = new Date(timestamp);
  const timeZoneOffset = updatedTime.getTimezoneOffset();
  updatedTime.setMinutes(updatedTime.getMinutes() - timeZoneOffset); // Adjusting for the local timezone
  const timeDifference = now.getTime() - updatedTime.getTime(); // in milliseconds

  const seconds = Math.floor(timeDifference / 1000);
  if (seconds < 60) {
    return `${seconds}s ago`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes}m ago`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours}h ago`;
  }

  const days = Math.floor(hours / 24);

  if (days < 30) {
    return `${days}d ago`
  } 
  const months = Math.floor(days / 30)
  if (months < 12) {
    return `${months}mon ago`
  } 

  const years = Math.floor(months / 12);

  return `${years}y ago`;
}
