import React from 'react';
import { Formik, Form, Field } from 'formik';
import { BiSearch } from "react-icons/bi";
import { Button, Select } from "antd";
import "./styles.scss";

const { Option } = Select;

interface IDiscoveryFilters {
    searchFilters: {
        searchQuery: string;
        minPrice: string;
        maxPrice: string;
        minOrders: string;
        maxOrders: string;
        minReviews: string;
        maxReviews: string;
        minRating: string;
        maxRating: string;
        origin: string;
    };
    handleSearchFilters: (values: any) => void;
    setSearchBtn: (val: boolean) => void;
    resetFiltersHandler: () => void;
}

const DiscoveryFilters: React.FC<IDiscoveryFilters> = ({ searchFilters, handleSearchFilters, setSearchBtn, resetFiltersHandler }) => {
    const countries: { [key: string]: string } = {
        Pakistan: "Pakistan",
        China: "China",
        Bangladesh: "Bangladesh",
        Nepal: "Nepal",
        "Sri Lanka": "Sri Lanka",
        "Myanmar [Burma]": "Myanmar",
    };

    return (
        <div className="product-discovery-container">
            <Formik
                initialValues={searchFilters}
                onSubmit={(values) => {
                    handleSearchFilters(values);
                    setSearchBtn(true);
                }}
                onReset={() => {
                    resetFiltersHandler();
                }}
            >
                {({ values, handleChange, handleReset }) => (
                    <Form>
                        <div className="input-container search-input">
                            <label className="filter-label">Keyword:</label>
                            <div className="search-input-container">
                                <Field
                                    className="input"
                                    type="text"
                                    name="searchQuery"
                                    placeholder="Enter product keyword"
                                />
                                <BiSearch />
                            </div>
                        </div>
                        <div className="min-max-filters">
                            <div className="input-container">
                                <label className="filter-label">Price:</label>
                                <div className="input-group">
                                    <Field
                                        className="input"
                                        type="number"
                                        name="minPrice"
                                        placeholder="Min"
                                    />
                                    <div>-</div>
                                    <Field
                                        className="input"
                                        type="number"
                                        name="maxPrice"
                                        placeholder="Max"
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <label className="filter-label">Monthly Orders:</label>
                                <div className="input-group">
                                    <Field
                                        className="input"
                                        type="number"
                                        name="minOrders"
                                        placeholder="Min"
                                    />
                                    <div>-</div>
                                    <Field
                                        className="input"
                                        type="number"
                                        name="maxOrders"
                                        placeholder="Max"
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <label className="filter-label">Rating:</label>
                                <div className="input-group">
                                    <Field
                                        className="input"
                                        type="number"
                                        name="minRating"
                                        placeholder="Min"
                                    />
                                    <div>-</div>
                                    <Field
                                        className="input"
                                        type="number"
                                        name="maxRating"
                                        placeholder="Max"
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <label className="filter-label">Reviews:</label>
                                <div className="input-group">
                                    <Field
                                        className="input"
                                        type="number"
                                        name="minReviews"
                                        placeholder="Min"
                                    />
                                    <div>-</div>
                                    <Field
                                        className="input"
                                        type="number"
                                        name="maxReviews"
                                        placeholder="Max"
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <label className="filter-label">Origin:</label>
                                <div className="input-group">
                                    <Field
                                        as={Select}
                                        name="origin"
                                        className="select-input"
                                        placeholder="Select Origin"
                                        filterOption={false}
                                        value={values.origin}
                                        onChange={(value: string) => handleChange({ target: { name: 'origin', value } })}
                                    >
                                        {Object.keys(countries).map(key => (
                                            <Option key={key} value={key}>
                                                {countries[key]}
                                            </Option>
                                        ))}
                                    </Field>
                                </div>
                            </div>
                            <div className="input-container action-btns">
                                <Button type="primary" htmlType="submit" className="btn search-btn">Search Products</Button>
                                <Button type="default" className="secondary-btn reset-btn" onClick={handleReset}>Reset Filters</Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default DiscoveryFilters;
