import React, { createContext, useState } from 'react'
import { IBlogContext, IPayloadSearch, RFC } from 'types/globals'

export const BlogContext = createContext<IBlogContext>({})

const BlogProvider: React.FC<RFC> = ({ children }) => {
    const [payloadSearch, setPayloadSearch] = useState<IPayloadSearch>({ page: 1, limit: 20, search: "" });

    const setPayloadSearchData = (payloadSearch: IPayloadSearch) => setPayloadSearch(payloadSearch);

    return (
        <BlogContext.Provider value={{
            payloadSearch,
            setPayloadSearchData
        }}>
            {children}
        </BlogContext.Provider>
    )
}

export default BlogProvider