import React from 'react'
import { RFC } from 'types/globals'
import Sidebar from "./Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import "./styles.scss"
import UserNav from "components/Nav/UserNav";
import NewsStrip from "components/Layouts/NewsStrip/NewsStrip";

const UserLayout: React.FC<RFC> = ({ children }) => {
    return (
        <div className="mainContainer">
            <Sidebar />
            <div id="contentContainer" className="content-container">
                <div style={{ width: "100%"}}>
                    <UserNav />
                    <NewsStrip/>
                    <Outlet />
                </div>
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default UserLayout;