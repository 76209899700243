import UserDashbaordLayout from "layouts/UserDashbaordLayout";
import Transition from "components/Transition";
import DiscoveryFilters from "./DiscoveryFilters/DiscoveryFilters";
import Text from "components/Text/Text";
import "./styles.scss"
import ProductCard from "./ProductCard/ProductCard";
import { IProductDiscovery } from "types/services";
import { useSubscription, useUser } from "hooks/user";
import { PlanType } from "enum/planTypes";
import { ROLES } from "enum/roles";
import LockedProductCard from "./ProductCard/LockedProductCard";
import { useGetProductDiscovery } from "hooks/products";
import { useEffect, useState } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductSkeletonCard from "./ProductCard/ProductSkeletonCard"
import { BiLock } from "react-icons/bi";
import ProductModal from "./ProductModal/ProductModal";
import { Select } from "antd";

const ProductDiscoveryInner = () => {

        const { subscription } = useSubscription();
    const { user } = useUser();
    const [searchBtn, setSearchBtn] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [searchFilters, setSearchFilters] = useState({
        searchQuery: "",
        minPrice: "",
        maxPrice: "",
        minReviews: "",
        maxReviews: "",
        minRating: "",
        maxRating: "",
        minOrders: "",
        maxOrders: "",
        sortBy: "",
        skip: 0,
        limit: 24,
        origin: "Pakistan"
    })
    const { Option } = Select;

    const sortFilter: { [key: string]: string } = {
        match: "Best Match",
        topSale: "Top Sale",
        newest: "Newest",
        priceLowHigh: "Price Low to High",
        priceHighLow: "Price High to Low",
    };
    const [discoveryProducts, setDiscoveryProducts] = useState<IProductDiscovery[]>([])
    const [activeFilters, setActiveFilters] = useState(searchFilters);
    const [selectedProduct, setSelectedProduct] = useState<number>(0)
    const [hasMore, setHasMore] = useState(true)
    const { products, total, isLoading } = useGetProductDiscovery({ 
        ...activeFilters 
    });    
    let userLocked = false;
    if( user?.role === ROLES.SUPER_ADMIN || 
        (subscription?.planType !== PlanType.FREE && subscription?.remainingDays)) {
        userLocked = false
    } else {
        userLocked = true
    }
    let totalProducts = userLocked ? 24 : total || 0
    let endMessage = userLocked ? <span className="lockerEnd"><BiLock size={20} /> <span>Upgrade your plan to discover more products</span></span> : "No more data found"

    const handleSearchFilters = (values: any) => {
        setSearchFilters(prevFilters => ({
            ...prevFilters,
            ...values
        }));
    };


    const handleProductSearch = () => {
        setSearchBtn(false)
        setDiscoveryProducts([])
        setActiveFilters(searchFilters); // Update the active filters to trigger fetch
    }

    useEffect(() => {
        if(searchBtn) handleProductSearch()
    }, [searchBtn])

    const fetchMoreData = () => {
        if(discoveryProducts.length && !userLocked) setActiveFilters({...searchFilters, skip: discoveryProducts.length}); // Update the active filters to trigger fetch
    };
    useEffect(() => {
        if (products?.length) {
            setDiscoveryProducts([...discoveryProducts, ...products])
        }
        if(products?.length === Number(totalProducts)) {
            setHasMore(false)
        } else {
            setHasMore(true)
        }
        if(userLocked)
            setHasMore(false)

    }, [products])

    const handleSortByFilter = (filter: string) => {
        handleSearchFilters({...activeFilters, 'sortBy': filter})
        setSearchBtn(false)
        setDiscoveryProducts([])
        setActiveFilters({...activeFilters, 'sortBy': filter});
    }
    const handleQuickView = (productId: number) => {
        setSelectedProduct(productId)
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };
    const resetFiltersHandler = () => {
        const emptyFilters = {
            searchQuery: "",
            minPrice: "",
            maxPrice: "",
            minReviews: "",
            maxReviews: "",
            minRating: "",
            maxRating: "",
            minOrders: "",
            maxOrders: "",
            sortBy: "",
            skip: 0,
            limit: 24,
            origin: ""
        };
        setSearchFilters(emptyFilters);
        setActiveFilters(emptyFilters);
    }
    function extractBaseUrl(url: string) {
        const regex = /^\/\/([^\/]+)/;
        const match = url.match(regex);
        if (match && match[1]) {
            return match[1];
        }
        return null;
    }
    const redirectToProductDetails = (productUrl:string, itemId: number) => {
        const baseUrl = extractBaseUrl(productUrl);
        return 'https://'+baseUrl+'/i'+itemId + '.html'
    }
    return (
        <>
        <div id="productList">
            <Text type={"h1"} className="page-title">Product Discovery</Text>
            <DiscoveryFilters setSearchBtn={setSearchBtn} searchFilters={activeFilters} handleSearchFilters={handleSearchFilters} resetFiltersHandler={resetFiltersHandler} />
            <div className="result-wrapper">
                <h3 className="text-left">{total ? `Products Found: ${total}` : ""}</h3>
                <div>
                    <label className="sort-by-label">Sort By:</label>
                    <Select
                        defaultValue={activeFilters.sortBy}
                        placeholder="Sort By"
                        className="sort-filter"
                        filterOption={false}
                        onChange={(value) => handleSortByFilter(value)}
                    >
                        {Object.keys(sortFilter).map((key) => (
                            <Option key={key} value={key}>
                                {sortFilter[key]}
                            </Option>
                        ))}
                    </Select>
                </div>
                
            </div>
            <div className="product-list">
                    <InfiniteScroll
                        dataLength={totalProducts}
                        className="infinite-scroll"
                        next={fetchMoreData}
                        loader={<ProductSkeletonCard />}
                        endMessage={
                            <p className="noMoreData">
                                <b>{endMessage}</b>
                            </p>
                        }
                        hasMore={hasMore}
                        scrollableTarget="contentContainer"
                    >
                        {discoveryProducts?.map((product:IProductDiscovery, index: any) => (
                            !userLocked ||
                            (userLocked && index < 4)
                            ? (
                                <ProductCard key={index} product={product} handleQuickView={handleQuickView} productUrl={redirectToProductDetails(product.productUrl, product.itemId )} />
                            )
                            : (
                                <LockedProductCard key={index} />
                            )
                        ))}
                    </InfiniteScroll>
            </div>
        </div>
        {selectedProduct ? (
            <ProductModal 
                itemId={selectedProduct} 
                isVisible={isModalVisible} 
                onClose={handleModalClose} 
            />
        ) : <></>}
        </>
    )
}

export default ProductDiscoveryInner