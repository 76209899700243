import axios from "axios";
import { Apis } from "constants/apis";
import { getToken } from "utils/token";


const getStoreDetails = async (payload: number) => {
    let queryParams = `?shopId=${payload}`;

    
    const { data } = await axios.get(`${Apis.STORE_DISCOVERY_DETAILS}${queryParams}`, { headers: { Authorization: getToken() } })
    return data
}

export default getStoreDetails