import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import getStores from "services/Stores/getStores";
import { useAppContext } from "./app";
import getStoreDetails from "services/Stores/getStoreDetails";
import { IGetAllStoresPayload, IGetStoreDiscoveryPayload } from "types/globals";
import searchStores from "services/Stores/searchStoreDiscovery";

export const useGetStores = (payload?: IGetAllStoresPayload) => {
  const toast = useToast();

  const onError = () => {
    toast({ title: "No Stores.", status: "error" });
  };

  const { isLoading, data, refetch } = useQuery(
    ["stores", payload],
    () => getStores(payload),
    { onError }
  );

  return { isLoading, stores: data?.stores, total: data?.total, refetch };
};

export const useGetStoreDiscovery = (payload?: IGetStoreDiscoveryPayload) => {
  const toast = useToast();

  const onError = () => {
    toast({ title: "No Stores.", status: "error" });
  };

  const { isLoading, data, refetch } = useQuery(
    ["stores", payload],
    () => searchStores(payload),
    { onError }
  );

  return { isLoading, stores: data?.stores, total: data?.total, refetch };
};

export const useGetStoreDetails = (payload: number) => {
  const toast = useToast();

  const onError = () => {
    toast({ title: "No store found", status: "error" });
  };

  const { isLoading, data, refetch } = useQuery(
    ["store", payload],
    () => getStoreDetails(payload),
    { onError }
  );

  return { isLoading, store: data, refetch };
};



