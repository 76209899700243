import axios from "axios";
import { Apis } from "constants/apis";
import { GetAllNotificationsService } from "types/services";
import { getToken } from "utils/token";

const getAllNotifications: GetAllNotificationsService = async (payload) => {

  const { page, limit } = payload;

  const { data } = await axios.get(
    `${Apis.NOTIFICATION}?${!!page ? `page=${page}&` : ''}${!!limit ? `limit=${limit}&` : ''}`,
    { headers: { Authorization: getToken() } }
  );

  return data;
};

export default getAllNotifications;
