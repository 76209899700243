import React from "react";
import Modal from "components/Modal/Modal";
import "./styles.scss";
import { Image } from "antd";
import StarRatingIcon from "assets/icons/StarRatingIcon";
import AreaChart from "components/Charts/AreaChart";
import { Card, Skeleton, Space } from 'antd';
import { useGetProductDetails } from "hooks/products";
import { formatRelativeTime } from "utils/helper";
interface IProductQuickView {
  itemId: number
  isVisible: boolean
  onClose: () => void
}

const ProductModal: React.FC<IProductQuickView> = ({ itemId, isVisible, onClose }) => {
    let { product, isLoading } = useGetProductDetails(itemId);    

    const dates = product?.trends?.map((item: any) => {
        const value = item?.month;
        return value;
    })  
    const priceAverageValues = product?.trends?.map((item: any) => {
        const value = item?.avg_price;
        return value;
    });
    const salesAverageValues = product?.trends?.map((item: any) => {
        const value = item?.avg_monthly_orders;
        return value;
    });

    const reviewsAverageValues = product?.trends?.map((item: any) => {
        const value = item?.avg_reviews;
        return value;
    });
    const revenueAverageValues = product?.trends?.map((item: any) => {
        const value = item?.avg_price *  item?.avg_monthly_orders;
        return value;
    });
    function extractBaseUrl(url: string) {
    const regex = /^\/\/([^\/]+)/;
    const match = url.match(regex);
    if (match && match[1]) {
        return match[1];
    }
    return null;
    }
    const redirectToProductDetails = () => {
        const baseUrl = extractBaseUrl(product.productUrl);
        if(baseUrl)
            window.open('https://'+baseUrl+'/i'+product.itemId + '.html', "_blank")
    }
    return (
    <Modal
      title="Quickview"
      isOpen={isVisible}
      onClose={onClose}
      okText={"View Details"}
      handleOk={redirectToProductDetails}
    >
        {!isLoading ? (
            <div className="modal-wrapper">
                <div className="top-wrapper">
                <div className="product-images-container">
                    <Image
                    src={product.image}
                    width={"100%"}
                    height={"100%"}
                    alt={"image"}
                    className="product-image"
                    preview={false}
                    />
                </div>
                <div className="product-details-wrapper">
                    <div className="top-details">
                        <h3 className="product-title">{product.name}</h3>
                        <div className="product-rating-wrapper">
                            <div className="product-rating">
                                <StarRatingIcon />
                                {Math.round(Number(product.avg_rating) * 10) / 10} ({parseInt(product.avg_reviews)})
                            </div>
                            <div className="seller-name">
                                by <span>{product.sellerName}</span> / <span>{product.brandName}</span>
                            </div>
                            </div>
                            <div className="price-container">
                                Rs. {product?.avg_price?.toLocaleString()}
                            </div>
                            <div className="origin">
                                Origin: <span>{product.location}</span>
                            </div>
                            <div className="last-checked">
                                Last Checked: {formatRelativeTime(product.lastChecked)}
                            </div>
                        </div>
                        <div className="sales-stats">
                            <div className="sales-card">
                                {parseInt(product.avg_weekly_orders)}
                                <span>Weekly Orders</span>
                            </div>
                            <div className="sales-card">
                                {parseInt(product.avg_monthly_orders)}
                                <span>Monthly Orders</span>
                            </div>
                            <div className="sales-card">
                                {parseInt(product.avg_all_time_orders)}
                                <span>All Time Orders</span>
                            </div>
                        </div>
                        <div className="sales-stats">
                            <div className="sales-card">
                                {(parseInt(product.avg_weekly_orders) * product.avg_price).toLocaleString()}
                                <span>Weekly Revenue</span>
                            </div>
                            <div className="sales-card">
                                {(parseInt(product.avg_monthly_orders) * product.avg_price).toLocaleString()}
                                <span>Monthly Revenue</span>
                            </div>
                            <div className="sales-card">
                                {(parseInt(product.avg_all_time_orders) * product.avg_price).toLocaleString()}
                                <span>All Time Revenue</span>
                            </div>
                        </div>
                        <div className="">
                            
                        </div>
                    </div>
                </div>
                <div className="product-trends-charts">
                    <div className="price-reviews-trends">
                        <AreaChart categories={dates} yAxis={"Sales"} dataCount={[{ name: 'Sales Trends', data: salesAverageValues }]} />
                        <AreaChart categories={dates} yAxis={"Revenue"} dataCount={[{ name: 'Revenue Trends', data: revenueAverageValues }]} />
                        <AreaChart categories={dates} yAxis={"Price"} dataCount={[{ name: 'Price Trends', data: priceAverageValues }]} />
                        <AreaChart categories={dates} yAxis={"Reviews"} dataCount={[{ name: 'Reviews Trends', data: reviewsAverageValues }]} />
                    </div>
                </div>
            </div>
        ) : (
             <Card
                className="skeleton-product-detail"
                >
                <Space className="skeleton-container">
                    <Skeleton.Image className="skeleton-image" active />
                    <Space className="skeleton-detail-container">
                        <Skeleton.Input active size={"large"} block={false} className="title-skeleton" />
                        <Space className="rating-skeleton">
                            <Skeleton.Button active size={"small"} block={false} className="price-skeleton" />
                            <Skeleton.Input active size={"small"} block={false} className="price-skeleton" />
                        </Space>
                        <Space className="price-container">
                            <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                        </Space>
                        <Space className="stats-container">
                             <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                            <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                        </Space>
                        <Space className="stats-container">
                             <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                            <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                        </Space>
                        <Space className="stats-container">
                             <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                            <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                        </Space>
                        <Space className="stats-container">
                             <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                            <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                        </Space>
                        <Space className="stats-container">
                             <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                            <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                        </Space>
                        <Space className="stats-container">
                             <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                            <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                        </Space>
                    </Space>
                </Space>
            </Card>
        )}
      
    </Modal>
  );
}

export default ProductModal;
