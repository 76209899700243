import { PERMISSIONS } from 'enum/permissions'
import { useUser } from 'hooks/user'
import React from 'react'
import FullScreenLoader from './FullScreenLoader';

interface IPermissionProps {
    permission: PERMISSIONS;
    Element: React.ReactNode;
}

const Permission: React.FC<IPermissionProps>  = ({ permission, Element }) => {
  const { user, isLoading } = useUser()
  
  if(isLoading) return <FullScreenLoader />

  if(user?.permissions?.includes(permission))
  return <React.Suspense fallback={<FullScreenLoader />}>{Element}</React.Suspense>

  return <></>
}

export default Permission