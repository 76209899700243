import { useColorModeValue } from "@chakra-ui/react";

const useColors = () => {
    const backgroundColor = useColorModeValue("white", "gray.700")
    const textColor = useColorModeValue("black", "whiteAlpha.800")
    const primaryColor = useColorModeValue("#185ADB", "#185ADB")
    const secondaryColor = useColorModeValue("#0A1931", "#0A1931")
    const textWhite = useColorModeValue("white", "white")
    const gray = useColorModeValue('#E8E8E8','#E8E8E8')

    return { backgroundColor, textColor, primaryColor, secondaryColor, textWhite, gray }
}

export default useColors;