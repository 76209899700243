import { CenterProps, FlexProps } from "@chakra-ui/react";

export const MainWrapper: FlexProps = {
  p: "20px",
  justifyContent: "center",
  w: "100%",
  maxW:"1420px",
  mx: "auto",
  mt: "30px"
};

export const InnerWrapper: FlexProps = {
  flexDirection: "column",
  gap: "20px",
  w: "100%",
};

export const wrapper: CenterProps = {
  flexDir: "column",
  h: "max-content",
  gap: "20px",
  w: "100%",
};
