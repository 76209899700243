import { Button, Flex, Image } from "@chakra-ui/react";
import notFoundSvg from "assets/svgs/notfound.svg"
import { useUser } from "hooks/user";
import { useNavigate } from "react-router-dom";

const Permissionpage = () => {
    const navigate = useNavigate()
    const { user } = useUser();

    const handleNavigate =()=>{
        if(user){
            navigate("/user/dashboard")
        }else{
            navigate("/");
        }
    }

    return (
        <>
            <Flex flexDir="column" justify="center" align="center" gap={4} h="100vh" w="100vw">
                <Image src={notFoundSvg} alt="not-found" />
                {/* <Text fontSize="36px" w="35%" fontWeight={500} fontFamily="Roboto" color="#AEAEAE" textAlign="center">Page not found.</Text> */}
                <Button bg="#185ADB" _hover={{ bg: "#185ADB" }} onClick={handleNavigate} h="54px" w="214px" color="#fff" fontSize="16px" mt={10} fontWeight={500}>HomePage</Button>
            </Flex>
        </>
    )
}
export default Permissionpage