import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useState } from "react";
import { Routes } from "constants/routes";
import useColors from "hooks/useColors";
import "./nav.scss";
import WhiteLogo from "assets/svgs/whiteLogo.svg";
import { BiHeart, BiSolidDashboard, BiCategory } from "react-icons/bi";
import { FaStore } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import DashboardIcon from "assets/svgs/dashboardIcon.svg";
import { RiSecurePaymentFill } from "react-icons/ri";
const links = [
    { to: Routes.DASHBOARD, name: "Dashboard", Icon: BiSolidDashboard },
    { to: Routes.DASHBOARD_PRODUCT_DISCOVERY, name: "Product Discovery", Icon: BiCategory },
    { to: Routes.PRODUCT_COLLECTION, name: "My Collections", Icon: BiHeart },
    { to: Routes.DASHBOARD_BILLING, name: "Billing Plans", Icon: FaStore },
    {
      to: Routes.DASHBOARD_INVOICES,
      name: "Payment History",
      Icon: RiSecurePaymentFill,
    },
];

const NavDrawer: FC<any> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeBox, setActiveBox] = useState<Routes>(Routes.DASHBOARD);
  const { primaryColor, secondaryColor } = useColors();
  const activate = (to: Routes) => {
    setActiveBox(to);
    return {};
  };
  return (
    <>
      <Image src={DashboardIcon} alt="nav" cursor="pointer" onClick={onOpen} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        // size="sm"
      >
        <DrawerOverlay />
        <DrawerContent bg="#0A1931">
          <DrawerCloseButton color={"white"} />
          <DrawerHeader>
            <NavLink to="/">
            <Image src={WhiteLogo} alt="logo" />
            </NavLink>
          </DrawerHeader>
          <DrawerBody textColor={"white"}>
            <Flex flexDir="column" align="left" gap={4}>
              {links?.map(({ to, name, Icon }) => {
                return (
                  <NavLink
                    to={to}
                    key={name}
                    style={({ isActive }) => (isActive ? activate(to) : {})}
                  >
                    <Flex
                      h="45px"
                      align="center"
                      flexDir="row"
                      gap="20px"
                      bgColor={activeBox === to ? "white" : "transparent"}
                      color={activeBox ? secondaryColor : "white"}
                      transition="all 0.1s ease-in-out"
                      w="100%"
                      padding="10px"
                      borderRadius="6px"
                    >
                      <Icon
                        color={activeBox === to ? primaryColor : "white"}
                        fontSize="20px"
                      />
                      <Text
                        w="max-content"
                        fontSize="16px"
                        textAlign="left"
                        color={activeBox === to ? primaryColor : "white"}
                      >
                        {name}
                      </Text>
                    </Flex>
                  </NavLink>
                );
              })}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default NavDrawer;
