import axios from "axios"
import { Apis } from "constants/apis"
import { SignupService } from "types/services"
import { getToken } from "utils/token"

const signup: SignupService = async(payload) => {
    const { data } = await axios.post(Apis.SIGNUP, payload, { headers: { Authorization: getToken() } })
    return data
}

export default signup