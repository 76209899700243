import axios from "axios"
import { Apis } from "constants/apis"
import { getToken } from "utils/token"

const sendWhatsappOtp = async (payload: any) => {
    const { data } = await axios.post(Apis.SEND_WHATSAPP_OTP, payload, { headers: { Authorization: getToken() } })
    return data
}
const validateWhatsappOtp = async (payload: any) => {
    const { data } = await axios.post(Apis.VALIDATE_WHATSAPP_OTP, payload, { headers: { Authorization: getToken() } })
    return data
}


export {sendWhatsappOtp, validateWhatsappOtp}