import axios from 'axios';
import { getToken } from 'utils/token';

const deleteSocialVideo = async ({ id }: { id: any }) => {
    const { data } = await axios.delete(`social-videos/${id}`, {
        headers: { Authorization: getToken() },
    });
    return data;
};

export default deleteSocialVideo;
