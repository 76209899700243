import axios from "axios";
import { Apis } from "constants/apis"
import { ForgotService } from "types/services";
import { decrypt } from "utils/security";


const forgot: ForgotService = async (payload) => {
    const { data } = await axios.post(Apis.FORGOT, payload);

    return decrypt(data?.data);
}

export default forgot