import { TextProps, ButtonProps, BoxProps} from '@chakra-ui/react';
import { IconBaseProps } from 'react-icons';

export const ModalWrapper : BoxProps = {
    w:"450px",
    minHeight:"150px",
    h:"auto",
    bg:"#FFFFFF",
    borderRadius:"12px",
    py:"25px",
    px:"35px",
    flexDirection:"column",
    gap:"39px",
    overflowY:"auto",
    maxHeight:"90vh"
}

export const ConfirmHeading: TextProps = {
    fontSize:"18px",
    fontWeight: 700,
    marginTop: "1rem",
    marginBottom: "0.5rem"
}

export const confirmDescription: TextProps = {
    fontSize: "14px",
    fontWeight: "400",
    marginBottom: "0.5rem"
}
export const TrashIcon : IconBaseProps = {
    color:"555353",
    cursor:"pointer",
    fontSize:"20px"}

export const ConfirmButton : ButtonProps ={
    h:"35px",
    w:"70px",
    variant:"secondary",
    fontSize:"12px",
}

export const CancelButton : ButtonProps ={
    h:"35px",
    w:"70px",
    variant:"secondary",
    fontSize:"12px",
    backgroundColor: "#E8E8E8",
    textColor: "#747474"
}
