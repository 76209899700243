import axios from "axios";
import { Apis } from "constants/apis"
import { TExtensionInstalledStatusUpdateService } from "types/services";
import { getToken } from "utils/token"

const extensionInstalled: TExtensionInstalledStatusUpdateService = async (payload) => {
    const { data } = await axios.post(Apis.EXTENSION_INSTALLED_STATUS, payload, { headers: { Authorization: getToken() } })
    return data
}

export default extensionInstalled