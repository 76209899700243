export enum Modals {
    PAYMENT_METHOD,
    PAAYMENT_DETAILS,
    PAYMENT_REVIEW,
    CARD_PAYMENT,
    CREATE_COLLECTION,
    PRODUCT_DETAILS,
    ADD_TO_COLECTION,
    DELETE_COLLECTION,
    INSTALL_POPUP,
    CONFIRMATION,
    PHONE_POPUP,
}