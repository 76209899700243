import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { FC } from "react";
import NavIcon from "assets/svgs/navIcon.svg";
import { Routes } from "constants/routes";
import "./nav.scss";
import Logo from "assets/svgs/nav-icon.svg";
import { NavLink } from "react-router-dom";
import { useUser } from "hooks/user";

const links: any[] = [
  { to: Routes.PRICING, name: "Pricing" },
  { to: Routes.BLOGS, name: "Blogs" },
  { to: Routes.CONTACT, name: "Contact Us" },
];

const NavDrawer: FC<any> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useUser();
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");

  return (
    <>
      <Image src={NavIcon} alt="nav" onClick={onOpen} />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
          <NavLink to="/">
            <Image src={Logo} alt="logo" />
          </NavLink>
          </DrawerHeader>
          <DrawerBody>
            <Flex flexDir="column" align="center" gap={4}>
              {links?.map((data) => {
                return (
                  <NavLink to={data?.to}>
                    <Text className="link-text">{data?.name}</Text>
                  </NavLink>
                );
              })}
                {user?.id ? (
        // <Box
        //   gap={isLargerThan1280 ? "40px" : "20px"}
        //   display={isLargerThan768 ? "flex" : "none"}
        // >
          <NavLink to={Routes.DASHBOARD}>
            <Flex
              justify="center"
              align="center"
              w="130px"
              h="40px"
              bg="#185ADB"
              borderRadius="4px"
              border="1px solid #185ADB"
            >
              <Text className="auth-btn" cursor="pointer">
                Dashboard
              </Text>
            </Flex>
          </NavLink>
        // </Box>
      ) : (
        <Box
          gap={isLargerThan1280 ? "40px" : "20px"}
          display= "flex"
          flexDir="column"
        >
          <NavLink to={Routes.SIGNIN}>
            <Flex
              justify="center"
              align="center"
              w="100px"
              h="40px"
              border="1px solid #111"
              bg="#fff"
              borderRadius="4px"
            >
              <Text
                className="auth-btn"
                cursor="pointer"
                color="black !important"
              >
                Signin
              </Text>
            </Flex>
          </NavLink>
          <NavLink to={Routes.SIGNUP}>
            <Flex
              justify="center"
              align="center"
              w="100px"
              h="40px"
              bg="#185ADB"
              borderRadius="4px"
              border="1px solid #185ADB"
            >
              <Text className="auth-btn" cursor="pointer">
                Signup
              </Text>
            </Flex>
          </NavLink>
        </Box>
      )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default NavDrawer;
