import { useMutation } from "@tanstack/react-query";
import subscribeNewsLetter from "services/NewsLetter/subscribe";
import {  useToast } from "@chakra-ui/react"
import createNewsLetter from "services/Admin/NewsLetter/createNewsLetter";

export const useSubscribeNewsLetter = () => {
  const toast = useToast()

  const onSuccess = (res: { message: "string"}) => {
    toast({ title:"Newsletter Sent!!", status:"success"})
  };

  const onError = (err: any) => {
    toast({ title: err?.response?.data?.message || err?.response?.data || "Something went wrong", status: "error" });
  };

  const { mutateAsync, isLoading, isError, error } = useMutation(
    ["subscribe"],
    subscribeNewsLetter,
    { onSuccess, onError }
  );

  return { subscribeNewsLetter: mutateAsync, isLoading, isError, error };
};



export const useCreateNewsLetter = () =>{
    const toast = useToast()

    const onSuccess = () => {
        toast({title:"Newsletter Sent!!", status:"success"})
    }

    const onError = (err: any) => {
        toast({ title: err?.response?.data?.message || err?.response?.data || "Something went wrong", status: "error" });
    }

    const {mutateAsync, isLoading} = useMutation(createNewsLetter, { onSuccess, onError })
    return { createNewsLetter: mutateAsync, creatingNewsLetter: isLoading }
}