import { Button, Image } from "antd"
import "./styles.scss"
import StarRatingIcon from "assets/icons/StarRatingIcon"
import dummyImg1 from "assets/img/prod-1.jpg"
import dummyImg2 from "assets/img/prod-2.jpg"
import dummyImg3 from "assets/img/prod-3.jpg"

import { Routes } from "constants/routes"
import { useNavigate } from "react-router-dom"
import { random } from "lodash"
import { BsUnlock } from "react-icons/bs"

const LockedProductCard = () => {
    const navigate = useNavigate()
    const productImg = [dummyImg1, dummyImg2, dummyImg3]
    return (
        <div className="locked-product-card product-card">
            <div className="overlay"></div>
            <div className="image-wrapper">
                <Image
                    src={productImg[random(0, 2)]}
                    width={"100%"}
                    height={"100%"}
                    alt={"image"}
                    className="product-image"
                    preview={false}
                />
            </div>
            <div className="product-details">
                <div>
                    <h5 className="product-title">Dummy Product</h5>
                </div>
                <div className="price-container">
                    Rs. 4,234
                </div>
                <div className="stats-container">
                    <div className="stat-item"><span>Rating</span><span className="flex"><StarRatingIcon />3.3 (233)</span></div>
                    <div className="stat-item"><span>Monthly Sales</span><span>232</span></div>
                    <div className="stat-item"><span>Growth Rate</span><span>32.3%</span></div>
                </div>
            </div>
            <div className="upgrade-container">
                <h6>Upgrade your plan to discover more trending products</h6>
                <Button className="primary-btn" onClick={() => navigate(Routes.DASHBOARD_BILLING)}>
                    <BsUnlock size={15} />
                    Unlock Product
                </Button>
            </div>
        </div>
    )
}

export default LockedProductCard