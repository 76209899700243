import React from 'react'
import {  Route } from "react-router-dom"
import FullScreenLoader from './FullScreenLoader'
import PrivacyPolicy from 'pages/HomePage/PrivacyPolicy'
import Compaigns from 'pages/HomePage/Compaigns'
import UninstallExtension from 'pages/HomePage/UninstallExtension/UninstallExtension'

const HomePage = React.lazy(() => import("pages/HomePage/HomePage"))
const ContactUs = React.lazy(() => import("pages/HomePage/ContactUs/ContactUs"))
const Pricing = React.lazy(() => import("pages/HomePage/Pricing/Pricing"))
const BlogsList = React.lazy(() => import("pages/HomePage/Blogs/BlogsList"))
const Faqs = React.lazy(() => import("pages/HomePage/FAQ/FaqsPage"))
const BlogDetails = React.lazy(() => import("pages/HomePage/Blogs/BlogDetails/BlogDetails"))
const Terms = React.lazy(() => import("pages/HomePage/Terms"))
const Forgot = React.lazy(() => import("pages/Auth/ForgotPassword/Forgot"))
const ResetPassword = React.lazy(() => import("pages/Auth/ResetPassword/ResetPassword"))
const VerifyEmail = React.lazy(() => import("pages/Auth/ResetPassword/VerifyEmail"))
const Reviews = React.lazy(()=> import("pages/HomePage/Reviews/ReviewPage"))
const ProfitCalculator = React.lazy(() => import("pages/HomePage/Calculator/CalculatorPage"))

const PublicRoutes = () => {
  return [
        <Route key={"routes"}>
            <Route key={"contact"} path="contact"  element={<React.Suspense  fallback={<FullScreenLoader />}><ContactUs /></React.Suspense>} />
            <Route key={"blogs/:id"} path="blogs/:id" element={<React.Suspense fallback={<FullScreenLoader />}><BlogDetails /></React.Suspense>} />
            <Route key={"blogs"} path="blogs" element={<React.Suspense fallback={<FullScreenLoader />}><BlogsList /></React.Suspense>} />
            <Route key={"faqs"} path="faqs" element={<React.Suspense fallback={<FullScreenLoader />}><Faqs /></React.Suspense>} />
            <Route key={"pricing"} path="pricing" element={<React.Suspense fallback={<FullScreenLoader />}><Pricing /></React.Suspense>} />
            <Route key={"terms-condition"} path="terms-condition" element={<React.Suspense fallback={<FullScreenLoader />}><Terms /></React.Suspense>} />
            <Route key={"privacy-policy"} path="privacy-policy" element={<React.Suspense fallback={<FullScreenLoader />}><PrivacyPolicy /></React.Suspense>} />
            <Route key={"home"} path="/" element={<React.Suspense fallback={<FullScreenLoader />}><HomePage /></React.Suspense>} />
            <Route key={"reset"} path="reset" element={<React.Suspense fallback={<FullScreenLoader />}><ResetPassword /></React.Suspense>} />
            <Route key={"verifyEmail"} path="verifyEmail" element={<React.Suspense fallback={<FullScreenLoader />}><VerifyEmail /></React.Suspense>} />
            <Route key={"forgot"} path="forgot" element={<React.Suspense fallback={<FullScreenLoader />}><Forgot /></React.Suspense>} />
            <Route key={"sellerdash-bundle"} path="sellerdash-bundle" element={<React.Suspense fallback={<FullScreenLoader />}><Compaigns /></React.Suspense>} />
            <Route key={"reviews"} path='reviews' element={<React.Suspense fallback={<FullScreenLoader/>}><Reviews/></React.Suspense>}/>
            <Route key={"daraz-profit-calculator"} path="daraz-profit-calculator" element={<React.Suspense fallback={<FullScreenLoader />}><ProfitCalculator /></React.Suspense>} />
            <Route key={"extension-chrome-uninstall"} path="extension/chrome-uninstall" element={<React.Suspense fallback={<FullScreenLoader />}><UninstallExtension /></React.Suspense>} />

        </Route>
  ]
}

export default PublicRoutes