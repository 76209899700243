export enum Routes {
  SIGNIN = "/signin",
  SIGNUP = "/signup",
  VERIFY = "/verify",
  FORGOT_PASSWORD = "/forgotPassword",
  HOME = "/",
  PRICING = "/pricing",
  CONTACT = "/contact",
  CALCULATOR = "/daraz-profit-calculator",
  DASHBOARD = "/user/dashboard",
  BLOGS = "/blogs",
  FAQS = "/faqs",
  TERMS = "/terms-condition",
  PRIVACY_POLICY = "/privacy-policy",
  THANK_YOU="/thank-you",
  DASHBOARD_PRODUCTS = "/user/products",
  DASHBOARD_PRODUCT_DISCOVERY = "/user/product-discovery",
  DASHBOARD_STORES = "/user/stores",
  DASHBOARD_STORE_DISCOVERY = "/user/store-discovery",
  DASHBOARD_MARKET = "/user/market",
  DASHBOARD_TRACKING = "/user/tracking/store-library",
  DASHBOARD_BILLING = "/user/billing",
  DASHBOARD_INVOICES = "/user/payments",
  DASHBOARD_PROFILE = "/user/profile",
  STORE_LIBRARY = "/user/tracking/store-library",
  TRACKING_LIST = "/user/tracking/tracking-list",
  PRODUCT_COLLECTION = "/user/collection-product",
  SUPPLIERS_COLLECTION = "/user/collection-suppliers",
  REDUCE_LAUNCHES = "users/reduce-launches",
  // Admin Routes
  ADMIN_DASHBAORD = "/admin/dashboard",
  ADMIN_REPORTS = "/admin/reports",
  ADMIN_USERS = "/admin/users",
  ADMIN_SALE = "/admin/sales",
  ADMIN_CUSTOMER = "/admin/customers",
  ADMIN_BLOG = "/admin/blogs",
  ADMIN_FAQ = "/admin/faq",
  ADMIN_NOTIFICATIONS = "/admin/notifications",
  ADMIN_COUPON = "/admin/coupons",
  ADMIN_PAYMENT = "/admin/payment-history",
  ADMIN_ROLES = "/admin/roles",
  ADMIN_PRODUCTS = "/admin/products",
  ADMIN_SUBSCRIPTION = "/admin/subsciptions",
  ADMIN_NEWS_LETTER = "/admin/news-letter",
  ADMIN_LOGS = "/admin/logs",
  ADMIN_REVIEW ="/admin/review",
  ADMIN_SOCIAL_VIDEOS ="/admin/social-videos",
  ADMIN_WEBSITE_SETTINGS = "/admin/website-settings"
}

export const YOUTUBE_LINK = 'https://www.youtube.com/@thesellerdash'
export const FACEBOOK_LINK = 'https://www.facebook.com/theSellerdash'
export const INSTAGRAM_LINK = 'https://www.instagram.com/thesellerdash'
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/thesellerdash'
export const WHATSAPP_LINK = 'https://web.whatsapp.com/send?phone=+923174466178'
export const CHROME_EXTENSION_LINK = "https://chrome.google.com/webstore/detail/sellerdash-daraz-product/cadnljldbdgkkecmonojainnjkbfaamm?hl=en-GB"
export const SUPPORT_EMAIL="ask@sellerdash.pk"
export const SUPPORT_PHONE="+923174466178"
