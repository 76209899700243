import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import createSocialVideo from 'services/Admin/SocialVideos/createSocialVideo';
import deleteSocialVideo from 'services/Admin/SocialVideos/deleteSocialVideo';
import activeSocialVideos from 'services/Admin/SocialVideos/getActiveSocialVideos';
import allSocialVideos from 'services/Admin/SocialVideos/getAllSocialVideo';

import updateSocialVideo from 'services/Admin/SocialVideos/updateSocialVideo';

export const useGetAllSocialVideos = () => {
    const { data, isLoading, ...rest } = useQuery(['getAllSocialVideos'], () => allSocialVideos());
    return { socialVideos: data, isLoading, ...rest };
};

export const useGetActiveSocialVideos = () => {
    const { data, isLoading, ...rest } = useQuery(['getActiveSocialVideos'], () => activeSocialVideos());
    return { socialVideos: data, isLoading, ...rest };
};

export const useUpdateSocialVideo = () => {
    const toast = useToast();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const onSuccess = () => {
        toast({ title: 'Social video Updated Successfully', status: 'success' });
        queryClient.removeQueries({
            queryKey: ['getAllSocialVideos'],
        });
        navigate('/admin/social-videos');
    };

    const onError = (error: any) => {
        toast({ title: error?.response?.data?.message || 'Some error while updating the SocialVideo', status: 'error' });
    };
    const { mutateAsync, isLoading } = useMutation(updateSocialVideo, { onSuccess, onError });
    return { updateSocialVideo: mutateAsync, isLoading };
};

export const useCreateSocialVideo = () => {
    const toast = useToast();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const onSuccess = () => {
        toast({ title: 'Social video created!!', status: 'success' });
        queryClient.removeQueries({
            queryKey: ['getAllSocialVideos'],
        });
        navigate('/admin/social-videos');
    };

    const onError = (error: any) => {
        toast({ title: error?.response?.data?.message || 'Some error while creating the SocialVideo', status: 'error' });
    };

    const { mutateAsync, isLoading } = useMutation(createSocialVideo, { onSuccess, onError });
    return { createSocialVideo: mutateAsync, isLoading };
};

export const useDeleteSocialVideo = () => {
    const toast = useToast();
    const queryClient = useQueryClient();

    const onSuccess = () => {
        toast({ title: 'Social video deleted!!', status: 'success' });
        queryClient.removeQueries({
            queryKey: ['getAllSocialVideos'],
        });
    };

    const onError = () => {
        toast({ title: 'Error deleting social video!', status: 'error' });
    };

    const { mutate, isLoading } = useMutation(deleteSocialVideo, {
        onSuccess,
        onError,
    });

    const socialVideoDelete = (id: string) => {
        mutate({ id });
    };

    return { socialVideoDelete, isLoading };
};
