import React from 'react';
import { Modal as AntModal } from 'antd';

interface IModal {
    isOpen: boolean
    title: string
    children?: React.ReactNode | React.ReactNode[];
    onClose: () => void
    handleOk?: () => void
    okText?: string
}
const Modal: React.FC<IModal> = ({ isOpen, onClose, title, children, okText, handleOk }) => {

  return (
    <>
      <AntModal
        title={title}
        style={{ top: 20 }}
        open={isOpen}
        onCancel={onClose}
        width={1024}
        okText={okText}
        onOk={handleOk}
      >
        {children}
      </AntModal>
    </>
  );
};

export default Modal;