import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const theme = extendTheme({ 
  config,
  components: {
    Button: {
      variants: {
        primary: {
          backgroundColor: "#185ADB",
          _hover: { bgColor: "#0A1931" },
          _active: { bgColor: "#185ADB" },
          color: "white",
          _disabled: {
            bgColor: "#185ADB70",
            cusror: "not-allowed"
          },
        },
        secondary: {
          backgroundColor: "#0A1931",
          _hover: { bgColor: "#185ADB" },
          _active: { bgColor: "#0A1931" },
          color: "white",
          _disabled: {
            bgColor: "#0A193170",
            cusror: "not-allowed"
          },
        }
      }
    }
  },
  Input: {
    variants: {
      filled: {
        field: {
          _active: {
            borderColor: 'transparent', 
            boxShadow: 'none',
          },
        },
      },
    },
  },
  fonts: {
    body: `'Inter', sans-serif`,
  },
  colors: {
    lightGray: {
      100: '#9E9E9E',
      200: '#9E9E9E',
      300: '#9E9E9E',
      400: '#9E9E9E',
      500: '#9E9E9E',
      600: '#9E9E9E',
      700: '#9E9E9E',
      800: '#9E9E9E',
      900: '#9E9E9E'
    },
    primary: {
      100: "#185ADB",
      200: "#185ADB",
      300: "#185ADB",
      400: "#185ADB",
      500: "#185ADB",
      600: "#185ADB",
      700: "#185ADB",
      800: "#185ADB",
      900: "#185ADB"
    },
    secondary: {
      100: "#0A1931",
      200: "#0A1931",
      300: "#0A1931",
      400: "#0A1931",
      500: "#0A1931",
      600: "#0A1931",
      700: "#0A1931",
      800: "#0A1931",
      900: "#0A1931"
    },
    brand: {
      100: "#185ADB",
      200: "#185ADB",
      300: "#185ADB",
      400: "#185ADB",
      500: "#185ADB",
      600: "#185ADB",
      700: "#185ADB",
      800: "#185ADB",
      900: "#185ADB"
    },
    darkGreen: {
      100: '#06996D',
      200: '#06996D',
      300: '#06996D',
      400: '#06996D',
      500: '#06996D',
      600: '#06996D',
      700: '#06996D',
      800: '#06996D',
      900: '#06996D'
    }
  },
})

export default theme