import Confirmation from "modals/ConfirmationModal/Confirmation";
import InstallPopup from "modals/InstallPopup/InstallPopup";
import PhonePopup from "modals/PhoneNumberModal/PhonePopup";
import React from "react";

const ModalWrapper: React.FC = () => {
  const modals: React.FC[] = [InstallPopup, Confirmation, PhonePopup];
  return (
    <>
      {modals.map((Modal, id) => (
        <Modal key={id} />
      ))}
    </>
  );
};

export default ModalWrapper;
