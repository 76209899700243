import * as yup from 'yup';

export const createSocialVideoSchema = yup.object({
    heading: yup.string().required(),
    description: yup.string().required(),
    link: yup.string().required(),
    sortedIndex: yup.number().required(),
    active: yup.boolean().required(),
});

export const updateSocialVideoSchema = yup.object({
    id: yup.number().required(),
    heading: yup.string().required(),
    description: yup.string().required(),
    link: yup.string().required(),
    sortedIndex: yup.number().required(),
    active: yup.boolean().required(),
});
