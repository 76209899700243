import React from "react";
import Modal from "components/Modal/Modal";
import "./styles.scss";
import { Image } from "antd";
import StarRatingIcon from "assets/icons/StarRatingIcon";
import AreaChart from "components/Charts/AreaChart";
import { Card, Skeleton, Space } from 'antd';
import { useGetStoreDetails } from "hooks/stores";
import { formatRelativeTime } from "utils/helper";
interface IStoreQuickView {
  itemId: number
  isVisible: boolean
  onClose: () => void
}

const StoreModal: React.FC<IStoreQuickView> = ({ itemId, isVisible, onClose }) => {
    let { store, isLoading } = useGetStoreDetails(itemId);    

    const dates = store?.trends?.map((item: any) => {
        const value = item?.month;
        return value;
    })  
    const priceAverageValues = store?.trends?.map((item: any) => {
        const value = item?.avg_price;
        return value;
    });
    const salesAverageValues = store?.trends?.map((item: any) => {
        const value = item?.avg_monthly_orders;
        return value;
    });

    const reviewsAverageValues = store?.trends?.map((item: any) => {
        const value = item?.avg_reviews;
        return value;
    });
    const revenueAverageValues = store?.trends?.map((item: any) => {
        const value = item?.avg_monthly_revenue;
        return value;
    });
    function extractBaseUrl(url: string) {
    const regex = /^\/\/([^\/]+)/;
    const match = url.match(regex);
    if (match && match[1]) {
        return match[1];
    }
    return null;
    }
    const redirectToStoreDetails = () => {
        const baseUrl = extractBaseUrl(store.storeUrl);
        if(baseUrl)
            window.open('https://'+baseUrl+'/i'+store.itemId + '.html', "_blank")
    }
    return (
    <Modal
      title="Quickview"
      isOpen={isVisible}
      onClose={onClose}
      okText={"View Details"}
      handleOk={redirectToStoreDetails}
    >
        {!isLoading ? (
            <div className="modal-wrapper">
                <div className="top-wrapper">
                <div className="store-images-container">
                    <Image
                    src={store.brandLogo}
                    width={"100%"}
                    height={"100%"}
                    alt={"image"}
                    className="Store-image"
                    preview={false}
                    />
                </div>
                <div className="store-details-wrapper">
                    <div className="top-details">
                        <h3 className="store-title">{store.brandName}</h3>
                        <div className="store-rating-wrapper">
                            <div className="store-rating">
                                <StarRatingIcon />
                                {store.avg_rating} ({parseInt(store.avg_reviews)})
                            </div>
                            </div>
                            <div className="origin">
                                Origin: <span>{store.location}</span>
                            </div>
                            <div className="origin">
                                Total Active Products: <span>{store.totalProducts.toLocaleString()}</span>
                            </div>
                            <div className="last-checked">
                                Last Checked: {formatRelativeTime(store.lastChecked)}
                            </div>
                        </div>
                        <div className="sales-stats">
                            <div className="sales-card">
                                {store.avg_weekly_orders.toLocaleString()}
                                <span>Weekly Orders</span>
                            </div>
                            <div className="sales-card">
                                {store.avg_monthly_orders.toLocaleString()}
                                <span>Monthly Orders</span>
                            </div>
                            <div className="sales-card">
                                {store.avg_all_time_orders.toLocaleString()}
                                <span>All Time Orders</span>
                            </div>
                        </div>
                        <div className="sales-stats">
                            <div className="sales-card">
                                {(parseInt(store.avg_weekly_revenue)).toLocaleString()}
                                <span>Weekly Revenue</span>
                            </div>
                            <div className="sales-card">
                                {(parseInt(store.avg_monthly_revenue)).toLocaleString()}
                                <span>Monthly Revenue</span>
                            </div>
                            <div className="sales-card">
                                {(parseInt(store.avg_alltime_revenue)).toLocaleString()}
                                <span>All Time Revenue</span>
                            </div>
                        </div>
                        <div className="">
                            
                        </div>
                    </div>
                </div>
                <div className="store-trends-charts">
                    <div className="price-reviews-trends">
                        <AreaChart categories={dates} yAxis={"Sales"} dataCount={[{ name: 'Sales Trends', data: salesAverageValues }]} />
                        <AreaChart categories={dates} yAxis={"Revenue"} dataCount={[{ name: 'Revenue Trends', data: revenueAverageValues }]} />
                        <AreaChart categories={dates} yAxis={"Reviews"} dataCount={[{ name: 'Reviews Trends', data: reviewsAverageValues }]} />
                    </div>
                </div>
            </div>
        ) : (
             <Card
                className="skeleton-store-detail"
                >
                <Space className="skeleton-container">
                    <Skeleton.Image className="skeleton-image" active />
                    <Space className="skeleton-detail-container">
                        <Skeleton.Input active size={"large"} block={false} className="title-skeleton" />
                        <Space className="rating-skeleton">
                            <Skeleton.Button active size={"small"} block={false} className="price-skeleton" />
                            <Skeleton.Input active size={"small"} block={false} className="price-skeleton" />
                        </Space>
                        <Space className="price-container">
                            <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                        </Space>
                        <Space className="stats-container">
                             <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                            <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                        </Space>
                        <Space className="stats-container">
                             <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                            <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                        </Space>
                        <Space className="stats-container">
                             <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                            <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                        </Space>
                        <Space className="stats-container">
                             <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                            <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                        </Space>
                        <Space className="stats-container">
                             <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                            <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                        </Space>
                        <Space className="stats-container">
                             <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                            <Skeleton.Input active size={"small"} block={false} className="stats-skeleton" />
                        </Space>
                    </Space>
                </Space>
            </Card>
        )}
      
    </Modal>
  );
}

export default StoreModal;
