import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { useDeleteSocialVideo, useGetAllSocialVideos } from 'hooks/socialVideos';
import { AiOutlineEdit } from 'react-icons/ai';
import { CiTrash } from 'react-icons/ci';
import { Modals } from 'constants/modals';
import { useQueryClient } from '@tanstack/react-query';
import { useAppContext } from 'hooks/app';

interface Props {
    setEditedSocialVideo: React.Dispatch<React.SetStateAction<any>>;
    onOpen: any;
    onClose: any;
    isOpen: boolean;
    setType: React.Dispatch<React.SetStateAction<any>>;
}

export default function SocialVideosTable({ setEditedSocialVideo, onOpen, onClose, isOpen, setType }: Props) {
    const { socialVideos, isLoading } = useGetAllSocialVideos();
    const { socialVideoDelete } = useDeleteSocialVideo();
    const client = useQueryClient();
    const { openModal } = useAppContext();

    const handleDelete = (socialVideo: any) => {
        if (socialVideo?.id) {
            client.setQueryData(['confirmationModal'], {
                heading: 'Are you sure?',
                description: 'This action will delete the social video <b>' + socialVideo?.heading + '</b>',
                onYesCallback: () => {
                    socialVideoDelete(socialVideo?.id);
                },
            });
            openModal?.(Modals.CONFIRMATION);
        }
    };
    const onEdit = (socialVideo: any) => {
        setType('edit');
        onOpen();
        setEditedSocialVideo(socialVideo);
    };

    return (
        <>
            <Table variant="simple" colorScheme="black">
                <Thead>
                    <Tr color="black">
                        <Th>No.</Th>
                        <Th>Heading</Th>
                        <Th>Description</Th>
                        <Th>Link</Th>
                        <Th>Sorted Index</Th>
                        <Th>Status</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody color="#656262">
                    {socialVideos?.length === 0 ? (
                        <Tr>
                            {' '}
                            <Td colSpan={12}>
                                {' '}
                                <Text textAlign="center">No result found.</Text>{' '}
                            </Td>{' '}
                        </Tr>
                    ) : (
                        <>
                            {socialVideos?.map((data: any, index: any) => (
                                <Tr key={data.id}>
                                    <Td>{index + 1}</Td>
                                    <Td>{data?.heading}</Td>
                                    <Td>{data?.description}</Td>
                                    <Td>{data?.link}</Td>
                                    <Td>{data?.sortedIndex}</Td>
                                    <Td>{data?.active ? 'Active' : 'In Active'}</Td>
                                    <Td>
                                        <Flex gap={3}>
                                            <AiOutlineEdit cursor="pointer" onClick={() => onEdit(data)} />
                                            <CiTrash cursor="pointer" onClick={() => handleDelete(data)} />
                                        </Flex>
                                    </Td>
                                </Tr>
                            ))}
                        </>
                    )}
                </Tbody>
            </Table>
        </>
    );
}
