import { Button, Flex, Input, Text, useMediaQuery } from "@chakra-ui/react";
import { HiOutlineMail } from "react-icons/hi";
import "./news-letter.scss";
import { useSubscribeNewsLetter } from "hooks/newsletter";
import { useState, ChangeEvent } from "react";

const NewsLetter = () => {
  const { subscribeNewsLetter } = useSubscribeNewsLetter();
  const [isLargerThan768] = useMediaQuery("(min-width: 868px)");
  const [email, setEmail] = useState<string>("");
  const [touched, setTouched] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(false);

  const emailChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const inputEmail = e.target.value;
    const isValidEmail = /\S+@\S+\.\S+/.test(inputEmail);
    setEmail(inputEmail);
    setValidEmail(isValidEmail);
  };

    const handleSubscribe = async () => {
    setTouched(true);
    try {
        if (validEmail) {
            await subscribeNewsLetter({ email });
            setEmail("");
        }
    } catch (err) {
    }
  };

  return (
    <Flex
      h={isLargerThan768 ? "400px" : "270px"}
      w={isLargerThan768 ? "70%" : "90%"}
      bg="#185ADB"
      pos="absolute"
      gap="16px"
      zIndex={1}
      borderRadius={isLargerThan768 ? "15px" : "15px"}
      mt={isLargerThan768 ? "-225px" : "-150px"}
      flexDir="column"
      align="center"
      justify="center"
    >
      <Text className="subscribe-text">
        Subscribe to Our Newsletter & get the Coupon code.
      </Text>
      <Text className="subscribe-des">
        All your information is completely confidential
      </Text>
      <Flex className="box">
        <Flex align="center" gap={isLargerThan768 ? 2 : 1}>
          <HiOutlineMail size="40px" color="#185ADB" />
          <Input
            border={touched && !validEmail ? "2px solid red" : "none"}
            placeholder="Type your email"
            value={email}
            onChange={emailChangeHandler}
            _focus={{ boxShadow: "none", border: "none" }}
            
          />
        </Flex>
        <Button
          disabled={!validEmail}
          className="btn"
          onClick={handleSubscribe}
        >
          Subscribe
        </Button>
      </Flex>
    </Flex>
  );
};

export default NewsLetter;
