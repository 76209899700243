import axios from "axios";
import { Apis } from "constants/apis";
import { CreateNotificationsService } from "types/services";
import { getToken } from "utils/token";

const createNotification: CreateNotificationsService = async (payload) => {
  
  const { data } = await axios.post(Apis.NOTIFICATION, payload, {
    headers: { Authorization: getToken() },
  });
  return data;
};

export default createNotification;
