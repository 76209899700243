import axios from "axios";
import { Apis } from "constants/apis";
import { IGetProductDiscoveryPayload } from "types/globals";
import { getToken } from "utils/token";


const searchProducts = async (payload?: IGetProductDiscoveryPayload) => {
    let queryParams = `?`;
    queryParams += `searchQuery=${payload?.searchQuery}`
    queryParams += `&skip=${payload?.skip}&limit=${payload?.limit}`
    
    queryParams += payload?.minPrice ? `&minPrice=${payload.minPrice}` : ''
    queryParams += payload?.maxPrice ? `&maxPrice=${payload?.maxPrice}` : ''
    
    queryParams += payload?.minRating ? `&minRating=${payload.minRating}` : ''
    queryParams += payload?.maxRating ? `&maxRating=${payload?.maxRating}` : ''

    queryParams += payload?.minOrders ? `&minOrders=${payload.minOrders}` : ''
    queryParams += payload?.maxOrders ? `&maxOrders=${payload?.maxOrders}` : ''

    queryParams += payload?.minReviews ? `&minReviews=${payload.minReviews}` : ''
    queryParams += payload?.maxReviews ? `&maxReviews=${payload?.maxReviews}` : ''
    queryParams += payload?.origin ? `&origin=${payload?.origin}` : ''
    queryParams += payload?.sortBy ? `&sortBy=${payload?.sortBy}` : ''


    
    const { data } = await axios.get(`${Apis.SEARCH_PRODUCT_DISCOVERY}${queryParams}`, { headers: { Authorization: getToken() } })
    return data
}

export default searchProducts