import Transition from "components/Transition"
import "./styles.scss"
import { NavLink } from "react-router-dom"
import Logo from "assets/svgs/nav-icon.svg";
import UninstallFeedbackForm from "./UninstallFeebackForm/UninstallFeedbackForm";

const UninstallExtension = () => {
    return (
        <Transition>
            <div className="main-wrapper">
                <div className="content-container">
                    <div className="logo-wrapper">
                        <NavLink to="/">
                            <img src={Logo} alt="logo" />
                        </NavLink>
                    </div>
                    <h1>We Care Deeply About Your Voice</h1>
                    <div className="feedback-form-container">
                        <div>
                            <div>
                                <UninstallFeedbackForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    )
}

export default UninstallExtension